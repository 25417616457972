import { InternalClientActionType } from "../types"

export function eventSummaryReducer(
  state = {
    fetching: undefined,
    data: undefined
  },
  action
) {
  switch (action.type) {
    case InternalClientActionType.GET_EVENT_SUMMARY_COMPLETE:
      return {
        fetching: false,
        data: action.eventSummary
      }
    case InternalClientActionType.GET_EVENT_SUMMARY:
      return {
        ...state,
        fetching: true
      }
    default:
      return state
  }
}
