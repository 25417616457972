import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

type InfoSectionProps = {
  name: string,
  url: string,
  title: string,
};

const InfoSection = ({
  name,
  title,
  url,
}: InfoSectionProps) => {
  const styles = useStyles();

  if (!name) {
    return null;
  }

  return (
    <div
      itemScope
      itemProp='organizer'
      itemType='http://schema.org/Person'
    >
      <div className={styles.title}>
        {title}
      </div>

      {url ? (
        <a
          target='_blank'
          rel="noopener noreferrer"
          href={url}
          className={styles.link}
        >
          {name}
        </a>
      ) : (
        <p itemProp='name' className={styles.text}>
          {name}
        </p>
      )}
    </div>
  );
}

type OrgAndTimerProps = {
  event: {
    isPublishedAthlinks: boolean;
    organization: {
      name: string;
      url: string;
    };
    timer: {
      name: string;
      url: string;
    };
  };
};

export const OrgAndTimer = ({ event }: OrgAndTimerProps) => {
  const { t } = useTranslation();

  if (!event.isPublishedAthlinks) {
    return null;
  }

  const hasOrganization = !!(event.organization && event.organization.name);
  const hasTimer = !!(event.timer && event.timer.name);

  return (
    <>
      {hasOrganization && <InfoSection title={t('Organizer')} {...event.organization} />}

      {hasTimer && <InfoSection title={t('Timer')} {...event.timer} />}
    </>
  );
};
