import React from 'react';
import {styles} from './styles';
import {AthletePhoto} from '../athlete/AthletePhoto';
import {Location} from '../shared/Location';
import {Link} from 'react-router-dom';

export const ProfilePreview = (props) => {
  const {
    url,
    useLink,
    Photos,
    RacerId,
    DisplayName,
    City,
    StateProvAbbrev,
    CountryId3,
    Gender,
    Age,
    Bib,
    RaceGoals,
    isMobile,
    showAthletePhoto,
    claimLink,
    nameOnly = false,
    showGender,
    t
  } = props;

  const hasLocation = City || StateProvAbbrev || CountryId3;
  const link = url
    ? <Link to={url} className={'athName ' + (url ? 'athName-display' : '')} style={styles.profileNameLink(isMobile)}>
        {DisplayName}
      </Link>
    : <span className={'athName ' + (url ? 'athName-display' : '')}>
        {DisplayName}
      </span>

  return (
    <div style={styles.profileCell}>
      {
        !isMobile &&
        <div style={styles.profilePhotoContainer}>
          {
            showAthletePhoto &&
            <AthletePhoto
              isMember={!!RacerId}
              photo={Photos}
              name={DisplayName}
              width={48}
            />
          }
          {
            claimLink
          }
        </div>
      }
     <div style={styles.profileNameContainer(isMobile)} className='row'>
        <div className='col- starter-row'>
          <div style={styles.profileNameLink(isMobile)}>
            {link}
          </div>
        </div>
        {
          !nameOnly &&
          <div className='col-12 pl-0' style={{lineHeight: '15px'}}>
            <span style={styles.profileAge(hasLocation)}>
              {
                Age ? t('{{gender}} {{age}}', {gender: Gender, age: Age})
                  : showGender ? t('{{gender}}', {gender: Gender})
                  : ''
              }
            </span>
            {
              Bib &&
              <span style={styles.profileBib(hasLocation)}>
                {t('Bib {{bibNumber}}', {bibNumber: Bib})}
              </span>
            }
            <Location
              city={City}
              state={StateProvAbbrev}
              country={isMobile ? '' : CountryId3}
              searchType={useLink ? 'athletes' : null}
              icon={null}
              style={styles.profileLocation}
            />
          </div>
        }
        {
          !nameOnly && RaceGoals &&
          <div className='col-12 pl-0' style={styles.goalsQuote}>
            "{RaceGoals.replace(/'\\/g, '')}"
          </div>
        }
      </div>
    </div>
    )
};

ProfilePreview.defaultProps = {
  useLink: true,
};
