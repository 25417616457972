import { InternalClientActionType } from "./types";
import { ResultActionTypes } from "./types";

export const getApiEventResults = (
  args,
  pollingInterval
) => ({
  type: ResultActionTypes.GET_EVENT_RESULTS,
  args,
  pollingInterval: +pollingInterval
})

export const getEventResultsError = (
  action
) => ({
  ...action,
  type: ResultActionTypes.GET_EVENT_RESULTS_ERROR
})

export const getEventResultsErrorInternal
  = (
    args,
    url,
    error
  ) => ({
    type: InternalClientActionType.GET_EVENT_RESULTS_ERROR,
    error,
    args,
    url
  })

export const getEventResultsInternal = (
  action
) => (
    {
      ...action,
      type: InternalClientActionType.GET_EVENT_RESULTS_DATA
    }
  )

export const getEventResultsSubscribed =
  (action) => ({
    ...action,
    type: ResultActionTypes.GET_EVENT_RESULTS_SUBSCRIBED
  })

export const getEventResultsUpdate = (
  action
) => ({
  ...action,
  type: ResultActionTypes.GET_EVENT_RESULTS_UPDATE
})

export const getEventResultsStartedInternal = (args) => (
  {
    type:
      InternalClientActionType.GET_EVENT_RESULTS_DATA_STARTED_POLLING,
    args
  }
)

export const getEventResultsStoppedInternal = (
  args
) => (
    {
      type: InternalClientActionType.GET_EVENT_RESULTS_DATA_STOPPED_POLLING,
      args
    }
  )

export const getEventResultsUpdateInternal
  = (
    results,
    args
  ) =>
    ({
      type: InternalClientActionType.GET_EVENT_RESULTS_DATA_UPDATE,
      results,
      args
    })
