import { useState } from "react"
import { useGetResultsBibNameSearchQuery } from "../../api/reigniteApi"
import useDebounce from "../../hooks/useDebounce"
import { TypeAhead } from '../shared/TypeAhead'
// @ts-ignore
import Search from 'react-icons/lib/md/search'
import { colors } from '../../shared/styles'
import { styles } from './styles';
import { TypeaheadOption } from "./TypeaheadOption"
import { withTranslation } from "react-i18next"
import {EventMetadataState} from '../../model/eventResultsState'

type Props = {
  eventId: number
  eventMetadata: typeof EventMetadataState
  ignorePrivacySettings: boolean
  isMobile: boolean
  masterEventId: number
  t: (value: string) => string
}

const EventResultsBibNameSearch = ({
  eventId,
  eventMetadata,
  ignorePrivacySettings = true,
  isMobile,
  masterEventId,
  t,
}: Props) => {
  const [term, setTerm] = useState('')
  const debounce = useDebounce(term, 1000)
  const { data, error, isLoading, refetch } = useGetResultsBibNameSearchQuery({
    eventId,
    from: 0,
    limit: 20,
    term: debounce,
  }, {
    skip: !debounce,
  })

  let flattenedResults = data?.map(result => ({ ...result, eventId, masterEventId })) || []

  return (
    <span>
      <TypeAhead
        InputIcon={() =>
          <Search
            size={20}
            color={colors.greyLine}
            style={{ verticalAlign: 'bottom' }}
          />}
        inputProps={{
          id: 'event-results-search',
          fullWidth: true,
        }}
        useMaterialUi
        value={term}
        placeholder={
          isMobile ? t('Search by name or bib') : t('Search by name or bib number')
        }
        dropdownHeight={300}
        dropdownTop={39}
        hideDropdownOverride={!term}
        options={term ? flattenedResults : []}
        dropdownStyle={styles.typeaheadDropDown}
        renderOption={(props: any) => <TypeaheadOption
          ignorePrivacySettings={ignorePrivacySettings}
          eventMetadata={eventMetadata?.data}
          {...props}
        />}
        onChange={(e: { target: { value: string } }) => {
          setTerm(e.target.value)
        }}
        onOptionSelected={() => { }}
        searching={isLoading}
        errorMessage={error}
        retrySearch={() => refetch()}
        width={isMobile ? '85%' : '100%'}
      />
    </span>
  );
}

export default withTranslation()(EventResultsBibNameSearch)