import { InternalClientActionType } from "./types";
import { ResultActionTypes } from "./types";

export const getMediaStopInternal = (
    action
  ) => (
      {
        ...action,
        type: InternalClientActionType.GET_MEDIA_DATA_STOP_POLLING
      }
    )

export const getMediaStop = (
  args
) => ({
  type: ResultActionTypes.GET_MEDIA_STOP,
  args
})
