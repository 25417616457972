import * as ResultsHubClient from '@eventops/athlinks-results-hub-client'
import { internalStore } from '../../App'
import { Log } from '../../logger'
import { subscribe, baseUrl, suppressSubscriptionErrors } from '../config'

export const getUrl = (url) =>
  encodeURI(`${baseUrl()}/${url}`)

export const parseErrorMessage = (
  e
) => {
  if (typeof e === 'object' && e.error) {
    const cleaner = JSON.parse(e.error)
    return (cleaner.message) ? cleaner.message : e
  } else {
    return `${e}`
  }
}

export const checkForErrorResponse = (
  args,
  url,
  response,
  errorCreator,
  reject,
  st = internalStore
) => {
  // check if reject is there (this might be outside of a promise lifecycle)
  const rejectWithError = (error) => {
    const which = reject || st.dispatch
    which(errorCreator(args, url, parseErrorMessage(error)))
  }
  try {
    const { result, error } = JSON.parse(response)
    if (error) {
      rejectWithError(error)
    } else if (result) {
      return JSON.parse(result)
    } else {
      rejectWithError(error)
    }
  } catch (error) {
    rejectWithError(error)
  }
}

export const callbackMap = {}

export const pollForData = (
  args,
  url,
  pollingInterval,
  actionCreator,
  succesfulResponse,
  errorCreator,
  st = internalStore) =>
  new Promise((resolve, reject) => {
    const fullUrl = getUrl(url)
    Log.debug(`Polling for data at '${fullUrl}' ` +
      `every ${pollingInterval}ms`)
    const callback = (response) => {
      const json = checkForErrorResponse(
        args,
        url,
        response,
        errorCreator,
        resolve
      )
      if (resolve && json) {
        st.dispatch(succesfulResponse)
        resolve = undefined
      }
      Log.info('Response for url', fullUrl, ':', JSON.stringify(json, null, 2))
      // we need to check if we are getting spurious updates
      if (json) {
        st.dispatch(actionCreator(json, args))
      }
      // publish errors on the store!
    }
    if (callbackMap[url]) {
      const message = `Duplicate subscription detected on ${url}, ` +
        `only one subscription can exist per runtime.  That includes ` +
        `parameters, so please check your args.`
      Log.error(message)
      if (!suppressSubscriptionErrors())
        reject(errorCreator(args, url, message))
    } else {
      callbackMap[url] = callback
    }
    subscribe(
      fullUrl,
      callback,
      pollingInterval
    ).catch((reason) => {
      Log.error('Subscription problem: ', reason)
      reject(errorCreator(args, url, reason))
    })
  })

export const stopPolling = (
  args,
  url,
  succesfulResponse,
  errorCreator
) =>
  new Promise((resolve, reject) => {
    try {
      const fullUrl = getUrl(url)
      const callback = callbackMap[url]
      if (!callback) {
        const message = `No subscription found on url: ${url}. ` +
          `Parameters count, so make sure to pass in the original ` +
          `args!`
        Log.error(message)
        reject(errorCreator(args, url, message))
      }
      // we get spurious extra messages while it's tryin to unmount
      const didIt = ResultsHubClient.unsubscribe(fullUrl, callback)
      if (resolve && didIt) {
        resolve(succesfulResponse)
        resolve = undefined
        callbackMap[url] = undefined
      }
    } catch (error) {
      reject(errorCreator(args, url, error))
    }
  })

export const waitForData = (
  args,
  url,
  actionCreator,
  errorCreator
) =>
  new Promise((resolve, reject) => {
    const fullUrl = getUrl(url)
    Log.info(`Requesting data from '${fullUrl}'`)
    try {
      const callback = (response) => {
        try {
          const json = checkForErrorResponse(
            args,
            url,
            response,
            errorCreator,
            reject
          )
          Log.info(`Response for url '${fullUrl}' recieved: ` +
            `${json}`)
          if (resolve && json) {
            ResultsHubClient.unsubscribe(fullUrl, callback)
            resolve(actionCreator(json, args))
            resolve = undefined
          }
        } catch (error) {
          reject(errorCreator(args, url, error))
        }
      }
      subscribe(fullUrl, callback)
    } catch (error) {
      reject(errorCreator(args, url, error))
    }
  })
