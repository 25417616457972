import { Box } from '@material-ui/core';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { showModal } from '../../actions/modalActions';
import { ModalTypes } from '../../actions/types';
import { EventInfo } from '../../components/eventInfo/EventInfo';
import { EventSummary } from '../../components/eventSummary/EventSummary';
import { FloatingComponents } from '../../components/floatingComponents/FloatingComponents';
import { RaceDistances } from '../../components/raceDistances/RaceDistances';
import { Schedule } from '../../components/schedule/Schedule';
import { Courses } from '../../components/ELP/courses/Courses';
import { Locations } from '../../components/ELP/locations/Locations';
import { Sponsors } from '../../components/sponsors/Sponsors';
import { canAddEventDate } from '../../components/cTABox/CTABox';
import { ELP } from '../../components/structuredData/ELP';
import { AddEventResultsButton } from '../../components/shared/AddEventResultsButton';
import { LogInOrSignUpIndependent } from '../../components/modal/LogInOrSignUpIndependent';
import { ElpResults } from '../../components/elpResults/ElpResults';
import { isLoggedIn } from '../../utils/isLoggedIn';
import { isTodayOrFuture } from '../../utils/date';
import { trackGoogleEvent } from '../../utils/googleEvents';

import { useStyles } from './styles';

const mapStateToProps = () => ({
  dispatch: undefined
});

type EventAboutPageComponentProps = {
  dispatch?: any;
  isMobile: boolean;
  masterEvent: any;
  courses: any;
  eventsObj: any;
  athlete: any;
  sponsorLogos: any;
};

const EventAboutPageComponent = ({
  courses,
  dispatch,
  isMobile,
  masterEvent,
  sponsorLogos,
  eventsObj,
}: EventAboutPageComponentProps) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const [loginOpen, setLoginOpen] = useState(false);

  const currRace = masterEvent && masterEvent.currentRace;
  const isDifferentEvent = (eventsObj.current !== eventsObj.next);

  const showScheduleOrDistances = masterEvent && isDifferentEvent;

  const addEventResultsModal = useCallback(() => {
    dispatch(showModal({
      modalProps: {
        currentPage: 0,
        event: {...masterEvent, country: masterEvent.countryName, state: masterEvent.stateProvAbbrev},
        isMobile,
        noScroll: true
      },
      modalType: ModalTypes.ADD_EVENT_WIZARD,
      pages: 2
    }))
  }, [dispatch, isMobile, masterEvent])

  const onClickAddMissingResults = useCallback(() => {
    trackGoogleEvent('ELP', 'Click', 'addMissingResults');
    isLoggedIn()
      ? addEventResultsModal()
      : setLoginOpen(true);
  }, [addEventResultsModal]);

  const resultsSection = useMemo(() => (
    <>
      <ElpResults
        event={masterEvent.currentRace}
        isMobile={isMobile}
      />

      <AddEventResultsButton callback={onClickAddMissingResults}
      />
    </>
  ), [isMobile, masterEvent.currentRace, onClickAddMissingResults])

  return (
    <div>
      <ELP
        event={currRace}
        sponsors={sponsorLogos}
      />

      <div className={styles.aboutPageContent}>
        <Box flex={1}>
          <LogInOrSignUpIndependent
            closeAction={() => setLoginOpen(false)}
            isMobile={isMobile}
            open={loginOpen}
          />

          {isDifferentEvent &&
            <div className={styles.aboutPageSummaryWrapper}>
              <EventSummary event={masterEvent.currentRace} />
            </div>
          }

          <div className={styles.aboutPageDistancesSection}>
            <div>
              {showScheduleOrDistances && (
                <>
                  {isTodayOrFuture(masterEvent.currentRace.startTime) ? (
                    <Schedule event={masterEvent.currentRace} courses={courses} />
                  ) : (
                    <RaceDistances event={masterEvent.currentRace} t={t} />
                  )}
                </>
              )}

              {isMobile && canAddEventDate(masterEvent.currentRace) &&
                <FloatingComponents
                  event={masterEvent.currentRace}
                  isMobile={isMobile}
                  t={t}
                />
              }

              {courses?.locations &&
                <Locations courses={courses} t={t} />
              }
            </div>

            <div>
              {!isMobile && canAddEventDate(masterEvent.currentRace) &&
                <FloatingComponents
                  event={masterEvent.currentRace}
                  isMobile={isMobile}
                  t={t}
                />
              }

              <EventInfo event={masterEvent.currentRace} />
            </div>
          </div>
        </Box>

        <div className={styles.aboutPageResultSectionDesktop}>
          {resultsSection}
        </div>
      </div>

      <div className={styles.aboutPageResultSectionMobile}>
        {resultsSection}
      </div>

      {isDifferentEvent && !_.isEmpty(courses) && !_.isEmpty(courses.courseMaps) &&
        <Courses courses={courses} />
      }

      {!!sponsorLogos.length && (
        <Sponsors logos={sponsorLogos} />
      )}
    </div>
  );
};

export const EventAboutPage = connect(mapStateToProps)(EventAboutPageComponent);
