import { ResultActionTypes } from "./types"
import { InternalClientActionType } from "./types"
import {
  getEventResultsError,
  getEventResultsInternal,
  getEventResultsSubscribed,
  getEventResultsUpdate
} from "./getEventResults"
import { getEventResultsStopInternal } from "./getEventResultsStop"
import {
  getIndividualResultsError,
  getIndividualResultsInternal,
  getIndividualResultsSubscribed,
  getIndividualResultsUpdate
} from "./getIndividualResults"
import {
  getIndividualResultsStopInternal
} from "./getIndividualResultsStop"
import {
  getMediaError, getMediaInternal, getMediaSubscribed, getMediaUpdate
} from "./getMedia"
import { getMediaStopInternal } from "./getMediaStop"
import { getMetaComplete, getMetaError, getMetaInternal } from "./getMeta"
import {
  getEventSummaryComplete, getEventSummaryError, getEventSummaryInternal
} from "./getEventSummary"

export const externalToInternalTypeMap = {
  [ResultActionTypes.GET_EVENT_RESULTS]:
    (action) => getEventResultsInternal(action),
  [ResultActionTypes.GET_EVENT_RESULTS_STOP]:
    (action) => getEventResultsStopInternal(action),
  [ResultActionTypes.GET_INDIVIDUAL_RESULTS]:
    (action) => getIndividualResultsInternal(action),
  [ResultActionTypes.GET_INDIVIDUAL_RESULTS_STOP]:
    (action) =>
      getIndividualResultsStopInternal(action),
  [ResultActionTypes.GET_META]:
    (action) => getMetaInternal(action),
  [ResultActionTypes.GET_MEDIA]:
    (action) => getMediaInternal(action),
  [ResultActionTypes.GET_MEDIA_STOP]:
    (action) => getMediaStopInternal(action),
  [ResultActionTypes.GET_EVENT_SUMMARY]:
    (action) => getEventSummaryInternal(action),
  // eslint-disable-next-line no-useless-computed-key
  ['undefined']: undefined
}

export const internalToExternalTypeMap = {
  [InternalClientActionType.GET_EVENT_RESULTS_DATA_STARTED_POLLING]:
    (action) =>
      getEventResultsSubscribed(action),
  [InternalClientActionType.GET_EVENT_RESULTS_DATA_UPDATE]:
    (action) =>
      getEventResultsUpdate(action),
  [InternalClientActionType.GET_EVENT_RESULTS_ERROR]:
    (action) =>
      getEventResultsError(action),
  [InternalClientActionType.GET_META_DATA_COMPLETE]:
    (action) =>
      getMetaComplete(action),
  [InternalClientActionType.GET_META_DATA_ERROR]:
    (action) =>
      getMetaError(action),
  [InternalClientActionType.GET_EVENT_SUMMARY_COMPLETE]:
    (action) =>
      getEventSummaryComplete(action),
  [InternalClientActionType.GET_EVENT_SUMMARY_ERROR]:
    (action) =>
      getEventSummaryError(action),
  [InternalClientActionType.GET_INDIVIDUAL_RESULTS_DATA_STARTED_POLLING]:
    (action) =>
      getIndividualResultsSubscribed(action),
  [InternalClientActionType.GET_INDIVIDUAL_RESULTS_DATA_UPDATE]:
    (action) =>
      getIndividualResultsUpdate(action),
  [InternalClientActionType.GET_INDIVIDUAL_RESULTS_ERROR]:
    (action) =>
      getIndividualResultsError(action),
  [InternalClientActionType.GET_MEDIA_DATA_STARTED_POLLING]:
    (action) =>
      getMediaSubscribed(action),
  [InternalClientActionType.GET_MEDIA_DATA_UPDATE]:
    (action) =>
      getMediaUpdate(action),
  [InternalClientActionType.GET_MEDIA_ERROR]:
    (action) =>
      getMediaError(action),
  // eslint-disable-next-line no-useless-computed-key
  ['undefined']: undefined
}
