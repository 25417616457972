import {combineReducers} from 'redux'
import connectionTestReducer from './connectionTestReducer'
import { mediaReducer } from './mediaReducer'
import { metaReducer } from './metaReducer'
import {eventResultsReducer} from './eventResultsReducer'
import {eventSummaryReducer} from './eventSummaryReducer'
import {individualResultsReducer} from './individualResultReducer'

const rootReducer = combineReducers({
  connection: connectionTestReducer,
  eventResults: eventResultsReducer,
  eventSummary: eventSummaryReducer,
  media: mediaReducer,
  meta: metaReducer,
  individualResults: individualResultsReducer
})

export default rootReducer
