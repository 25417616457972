import { combineEpics } from 'redux-observable'
import { testConnectionEpic } from './connectionTestEpic'
import { getMetaData } from './metaEpic'
import {
  startEventResultsPolling,
  stopEventResultsPolling
} from './eventResultsEpic'
import { getEventSummaryEpic } from './eventSummaryEpic'
import {
  startIndividualResultsPolling,
  stopIndividualResultsPolling
} from './individualResultsEpic'
import { startMediaPolling, stopMediaPolling } from './mediaEpic'

const rootEpic = combineEpics(
  testConnectionEpic,
  getMetaData,
  startEventResultsPolling,
  stopEventResultsPolling,
  getEventSummaryEpic,
  startIndividualResultsPolling,
  stopIndividualResultsPolling,
  startMediaPolling,
  stopMediaPolling
)

export default rootEpic
