import { Box } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import momentTimezone from 'moment-timezone';
import tzlookup from 'tz-lookup';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { useMemo } from 'react';

import {connectStream} from '../../lib/bastetjs/utils/connectStream';
import eventBus from '../../lib/event-bus-rx';
import {EventHero} from '../../components/heroImage/EventHero';
import {NotFound} from '../notFound/NotFound';
import {PageTemplate} from '../home/PageTemplate';
import { OpenGraphTags } from '../../shared/OpenGraphTags';
import { StandardHeaderTags } from '../../shared/StandardHeaderTags';
import {EventResults} from '../eventResults/EventResults';
import {IndividualResult} from '../individualResult/IndividualResult';
import {EventRosterView} from '../eventRoster/EventRosterView';
import {getTokenRacerId} from '../../utils/isLoggedIn';
import {fetchCourse} from '../../data/ExtraEventStream';
import { getResultsUrl } from '../../utils/resultsHelpers';
import { heroImageUrl, getImage } from '../../utils/heroImageUrl';
import {Loading} from '../../components/shared/Loading';
import { trackGoogleEvent } from '../../utils/googleEvents';
import { useGetEventCoursesQuery } from '../../api/alaskaApi';
import { isRosterAvailable } from '../../utils/rosterHelpers';
import { useFirebaseConfig } from '../../lib/firebase/remote-config'
import { theme } from '../../shared/theme';
import {
  getEventOptions,
  getEventStatus,
  getHeroImage,
  checkHeroImage,
  setHeroImage,
} from '../../data/EventStreams';
import {
  fetchAthleteFollowing,
  getAthleteFollowingStream,
  getAthleteStream,
  fetchAthlete
} from '../../data/AthleteStreams';
import {
  fetchMasterEvent,
  getMasterEventStream,
  isInvalidMasterEventError
} from '../../data/MasterEventStreams';
import PropTypes from 'prop-types';


import { useStyles } from './styles';
import {EventAboutPage} from './EventAboutPage';
import { LearnMoreOrRegisterButton } from './LearnMoreOrRegisterButton';

const eventsObj = {};

const messagesPerTab = (t) => ({
  about: {
    title: (name) => t('{{name}} on Athlinks', {name}),
    description: (name) =>
      t('View information and results about {{name}} on Athlinks', {name})
  },
  updates: {
    title: (name) => `${name} Updates`,
    description: (name) => `Get the latest updates for ${name}.`
  },
  roster: {
    title: (name) => t('{{name}} Roster', {name}),
    description: (name) => t('View the Roster for {{name}}', {name})},
  results: {
    title: (name, isCourseView, courseName) => isCourseView
      ? t('{{name}}: {{courseName}} Results', {name, courseName})
      : t('{{name}} Results Leaderboard',{name}),
    description: (name, isCourseView, courseName) => isCourseView
      ? t('View {{courseName}} results for {{name}}', {name, courseName})
      : t('View the race leaderboard for {{name}}', {name})
  },
});

const MasterLandingPageComponent = (props) => {
  const {
    masterId,
    masterEvent = { isLoading: true },
    result,
    tab,
    heroImage,
    selectedEventStatus,
    eventCoursesForCurrentRace,
  
    // ERP route params
    eventId,
    courseId,
    divisionId,
    search,
    friendsFilter,
  
    // IRP params
    entryId,
    bib,
    source,
  
    isMobile,
    isLandscape,
    widthOffset,
    eventCourses = { isLoading: true },
    courses,
    following = {fetching: true, isLoading: true},
    athlete,
    referrerPath,
    mapPreview,
    firebaseConfig,
  } = props;

  const prevMasterId = useRef(masterId);
  const prevEventId = useRef(eventId);
  const prevAthlinksId = useRef(masterEvent?.currentRace?.athlinksId);

  const { t } = useTranslation();
  const styles = useStyles();

  const [heroCheckActive, setHeroCheckActive] = useState(false);

  useEffect(() => {
    if (masterId !== prevMasterId.current) {
      fetchMasterEvent(masterId);
    }
    if (masterEvent && masterEvent.currentRace) {

      /**
       * @TODO We will probably need to publish eventId and selectedCourseId
       *       and to do Observable.combineLatest on those two streams. After
       *       that this if conditions can be removed
       */
      const mEvent = masterEvent;

      const nextAthlinksId = mEvent.currentRace.athlinksId;
      const {currentRace = {}} = mEvent;
      const {affiliateRaceId, athlinksMasterId, eventType = '', hasHeroImage} = currentRace;
      //We check the hero image for existence so that we can properly add it to meta tags
      if (
        ((heroImage && heroImage.isLoading) || (masterId !== prevMasterId.current))
        && !heroCheckActive
        && affiliateRaceId
        && athlinksMasterId) {
          setHeroCheckActive(true);
          checkHeroImage(
            affiliateRaceId,
            athlinksMasterId,
            eventType.toLowerCase() || 'running',
            isMobile,
            hasHeroImage
          );
      } else if (((heroImage && heroImage.isLoading)
        || (masterId !== prevMasterId.current))
        && !affiliateRaceId
        && athlinksMasterId) {
        const size = isMobile ? 600 : 1600;
        const image = eventType
          ? getImage(eventType.toLowerCase(), size)
          : getImage('running', size)
        setHeroImage(athlinksMasterId, image);
      }

      if (heroImage && typeof(heroImage) === 'string') {
        setHeroCheckActive(false);
      }

      if (prevAthlinksId.current !== nextAthlinksId) {
        prevAthlinksId.current = nextAthlinksId;
        eventBus.publish('athlinksId', nextAthlinksId);
        eventsObj.next = nextAthlinksId;
      } else {
        eventsObj.next = -1;
      }

      if (prevEventId.current !== eventId) {
        getEventStatus('ERP-current-event', {eventId: eventId, masterId: masterEvent.masterEventID});
      }
    }

    prevMasterId.current = masterId;
    prevEventId.current = eventId;
  }, [masterId, masterEvent, heroImage, heroCheckActive, eventId, isMobile]);

  useEffect(() => {
    fetchMasterEvent(masterId);
    const racerId = getTokenRacerId();
    if (racerId) {
      fetchAthleteFollowing(racerId);
      fetchAthlete(racerId);
    }
  }, [masterId]);

  const getEventId = (isERP, override = false) => {
    const today = new Date();
    today.setHours(0,0,0,0);
    const todayTime = today.getTime();

    if (masterEvent?.isLoading) {
      return 0;
    }

    const erpEventId = (masterEvent?.eventRaces || []).reduce((prev, curr) => {
      const nextItemDate = new Date(curr.raceDate);
      nextItemDate.setHours(0,0,0,0);
      const nextItemDateTime = nextItemDate.getTime();
      const prevItemDate = new Date(prev.raceDate);
      prevItemDate.setHours(0,0,0,0);
      const prevItemDateTime = prevItemDate.getTime();

      if (prev.doesExist) {
        return prev;
      }
      else if (!override && curr.raceID === eventId) {
        return Object.assign({}, curr, {doesExist: true});
      }
      else if (isERP) {
        if (!prev.raceDate || (prevItemDate > nextItemDate && prevItemDateTime > todayTime)) {
          return curr;
        }
        else {
          return prev;
        }
      }
      else {
        if(nextItemDateTime >= todayTime) {
          return curr;
        }
        else if (prev.raceDate && new Date(prev.raceDate) > nextItemDate) {
          return prev;
        }
        else {
          return curr
        }
      }
    }, {});

    return erpEventId.raceID;
  };

  /**
  * If we have a timezone or lat/long, use logic that localizes it to the
  * event's timezone. Otherwise do a less accurate match.
  * @returns {boolean}
  */
  const isMostRecentEventLive = () => {
    const startEpoch = masterEvent.nextRaceDate ? masterEvent.nextRaceDate : false;
    if (!startEpoch) {
      return false;
    }

    const currentRace = masterEvent.currentRace;
    if (!currentRace) {
      return false
    }
    
    if (currentRace.startTime && currentRace.endTime) {
      const startTime = moment(currentRace.startTime)
      const endTime = moment(currentRace.endTime)

      return moment().isBetween(startTime, endTime)
    }

    if (currentRace.location.latitude && currentRace.location.longitude) {
      const timeZone = tzlookup(currentRace.location.latitude, currentRace.location.longitude);
      const date = new Date();
      const currentDateTZ = momentTimezone(date).tz(timeZone).format('MM/DD/YYYY');
      return new Date(startEpoch).setHours(0, 0, 0, 0) === new Date(currentDateTZ).setHours(0, 0, 0, 0);
    }
    else {
      return new Date(startEpoch).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);
    }
  }

  const getEventRaces = () => {
    return (masterEvent.EventRaces || []).find((item) => {
     return item.RaceID === eventId;
    });
  }

  const getRace = (eventCourseId, races) => {
    return races.races.filter((_) => _.eventCourseId === eventCourseId);
  }

  const extractRaceCourses = (masterEvent) => {
    if (masterEvent.eventRaces) {
      const eventCourses = masterEvent.eventRaces.map((race) => {
        return race.eventCourses.map((course) => {
          const epoch = moment.utc(race.raceDate).toDate().getTime();
          return {
            courseId: course.courseID,
            eventCourseId: course.eventCourseID,
            eventId: race.raceID,
            eventName: race.raceName,
            masterId: race.masterEventID,
            meters: course.distUnit,
            raceName: course.courseName,
            raceDate: race.raceDate,
            utcRaceDate: epoch,
            startEpoch: (epoch / 1000)
          }
        })
      });
      return {
        races: [].concat.apply([], eventCourses)
      }
    }
    else { return {races: []} }
  }

  const renderPage = (image) => {
    const {sponsorLogos = []} = courses || {};
    const currentRace = masterEvent.currentRace || {};

    if (tab === 'results') {
      trackGoogleEvent('ELP', 'Click', 'Results');
      //IRP
      if (entryId || bib) {
        const useRtkq = firebaseConfig.useRtkq?.asBoolean() ?? true
        return (
          useRtkq !== undefined &&
          <IndividualResult
            bib={bib}
            entryId={entryId}
            eventCourseId={courseId}
            eventId={eventId}
            image={image}
            isLandscape={isLandscape}
            isLive={isSelectedEventLive()}
            isMobile={isMobile}
            mapPreview={mapPreview}
            masterId={masterId}
            race={getRace(courseId, extractRaceCourses(masterEvent))}
            referrerPath={referrerPath}
            source={source}
            sponsorLogos={sponsorLogos}
            useRtkq={useRtkq}
            widthOffset={widthOffset}
          />
        )
      }
      //ERP
      return (
        <EventResults
          athlete={athlete}
          athleteId={getTokenRacerId()}
          courseId={courseId || 0}
          divisionId={divisionId || 0}
          courses={courses}
          eventCoursesDropDown={eventCourses.EventCoursesDropDown}
          eventId={getEventId(true)}
          eventName={currentRace.name}
          eventRaces={getEventRaces()}
          following={following}
          friendsFilter={friendsFilter}
          isEventLive={isSelectedEventLive()}
          isMobile={isMobile}
          isSearch={!!search}
          masterEvent={currentRace}
          masterId={masterId}
          noHeaders={true}
          races={extractRaceCourses(masterEvent)}
          search={search || ''}
          selectedCourseData={eventCourses.SelectedCourseData}
          schedule={courses?.schedules}
          sponsorLogos={sponsorLogos}
          startDate={masterEvent.nextRaceDate}
          timer={courses?.timer}
        />
      );
    }

    if (tab === 'roster') {
      return (
        <EventRosterView
          eventCourseId={courseId}
          masterId={masterId}
          isMobile={isMobile}
          athlete={athlete}
          races={masterEvent?.eventRaces?.filter((x) => !!x.eventCourses?.length)}
          eventId={masterEvent?.currentRace?.athlinksId}
        />
      );
    }

    return (
      <EventAboutPage
        eventId={getEventId()}
        masterEvent={masterEvent}
        isMobile={isMobile}
        courses={courses}
        races={extractRaceCourses(masterEvent)}
        eventsObj={eventsObj}
        following={following}
        masterId={parseInt(masterId)}
        athlete={athlete}
        result={result}
        sponsorLogos={sponsorLogos}
      />
    );
  }

  const registerOrLearnMoreButton = useMemo(() => {
    const thirdPartyRegLink = masterEvent?.currentRace?.thirdPartyRegLink;
    const CTLiveLink = thirdPartyRegLink || eventCoursesForCurrentRace?.CTLiveRegistrationLink;

    const startTime = masterEvent?.currentRace?.startTime
    const siteURL = masterEvent?.currentRace?.siteUri;
    const isExternalURL = siteURL && (siteURL.toLowerCase().indexOf('http://') === 0 || siteURL.toLowerCase().indexOf('https://') === 0);
    const realURL = !isExternalURL ? 'http://' + siteURL : siteURL;

    return (
      <LearnMoreOrRegisterButton
        CTLiveLink={CTLiveLink}
        masterId={masterId}
        realURL={realURL}
        startTime={startTime}
      />
    )
  }, [eventCoursesForCurrentRace, masterEvent, masterId])

  const isSelectedEventLive = () => {
    return (selectedEventStatus || {}).isLive;
  };

  const getErrorOverride = (error) => {
    if(isInvalidMasterEventError(error)) {
      return {
        status: t('Event not found'),
        message: t('This event page does not exist.')
      };
    }

    return {status: '', message: ''};
  }

  if (masterEvent?.isLoading) {
    return <Loading centered={true} />;
  }

  if (tab === 'roster' && !isRosterAvailable(masterEvent?.currentRace)) {
    return (<Redirect to={`/event/${masterId}`} />)
  }

  const mEvent = masterEvent.currentRace || {startTime: 0, location: {}};
  const isCurrentRaceToday = isMostRecentEventLive();
  const liveEventURL = getResultsUrl(masterId, eventId);

  if (!result?.isLoading && ((masterEvent && masterEvent.error) || (mEvent && !mEvent.athlinksId))) {
    return (
      <NotFound
        noRedirect={true}
        errorStatus={404}
        errorOverride={getErrorOverride(masterEvent.error)}
      />
    )
  }

  const {
    eventType,
    affiliateRaceId,
    isPublishedAthlinks,
    hasHeroImage,
  } = mEvent;

  //Meta tag consts
  const currentRace = masterEvent.currentRace || {};
  const selectedRace = masterEvent.eventRaces && masterEvent.eventRaces.length
    ? masterEvent.eventRaces.find(({raceID}) => eventId === raceID) || currentRace
    : currentRace || {};
  const isIRPView = !!(entryId || bib);
  const isCourseView = !!courseId && !isIRPView;

  const raceName = selectedRace && selectedRace.raceName
    ? selectedRace.raceName
    : selectedRace.name ? selectedRace.name : '';
  const selectedEventCourse = isCourseView && Array.isArray(selectedRace.eventCourses)
    ? selectedRace.eventCourses.find(({eventCourseID}) => courseId === eventCourseID) || {}
    : {};
  const messageSetKey = tab || 'about';
  const ogTitle = messagesPerTab(t)[messageSetKey].title(raceName, isCourseView, selectedEventCourse.courseName || '');
  const ogDescription = messagesPerTab(t)[messageSetKey].description(raceName, isCourseView, selectedEventCourse.courseName || '');
  const metaHeroImage = heroImage && heroImage.length
    ? heroImage
    : heroImageUrl(
      eventType || 'Running',
      isMobile,
      isPublishedAthlinks && hasHeroImage && affiliateRaceId,
      true
    );
  const imageHeight = isMobile ? 338 : 900;
  const imageWidth = isMobile ? 600 : 1600;
  const image = {image: metaHeroImage, imageHeight, imageWidth};
  const erpEventId = getEventId(true, true);

  return (
    <PageTemplate
      unifiedSearchMode={true}
      headerIsFixed={true}
      isMobile={isMobile}
      paddingTop={0}
      courses={courses}
      isLive={isCurrentRaceToday}
      isProfile={false}
      liveEventURL={liveEventURL}
      bannerTopOffset={50}
      t={t}
      {...props}
    >
      <StandardHeaderTags
        title={ogTitle}
        description={ogDescription}
      />
      <OpenGraphTags
        ogType='website'
        title={ogTitle}
        image={metaHeroImage}
        imageHeight={imageHeight}
        imageWidth={imageWidth}
        description={ogDescription}
      />
      <div
        itemScope
        key={masterId}
        itemType="http://schema.org/Event" className={styles.masterPageWrapper}
      >
        <div className={styles.masterPageContainer}>
          <EventHero
            actionButton={registerOrLearnMoreButton}
            excludeSearch={true}
            isMobile={isMobile}
            master={mEvent}
            eventId={eventId}
            erpEventId={erpEventId}
            isLiveRaceHeaderVisible={isCurrentRaceToday}
            liveEventURL={liveEventURL}
          />
          {!masterEvent?.isLoading && (
            <Box bgcolor={theme.palette.common.white}>
              {renderPage(image)}
            </Box>
          )}
        </div>
      </div>
    </PageTemplate>
  );
};

const PureMasterLandingPageComponent = (props) => {
  const athlinksEventId = props.eventId || props.eventOptions?.[0]?.id
  const { data: eventCourses } = useGetEventCoursesQuery(athlinksEventId, {
    skip: !athlinksEventId
  })

  const currentRaceEventId = (props.eventOptions || []).find(option => option.isCurrentRace)?.id
  const { data: eventCoursesForCurrentRace } = useGetEventCoursesQuery(currentRaceEventId, {
    skip: !currentRaceEventId
  })

  const firebaseConfig = useFirebaseConfig().config

  return (
    <MasterLandingPageComponent
      {...props}
      eventCourses={eventCourses}
      eventCoursesForCurrentRace={eventCoursesForCurrentRace}
      firebaseConfig={firebaseConfig}
    />
  )
}

MasterLandingPageComponent.propTypes = {
  masterId: PropTypes.any.isRequired, //needed by connectStream to re-render page on changed masterId
  courses: PropTypes.object,
  eventCourses: PropTypes.object,
  eventCoursesForCurrentRace: PropTypes.object,
  action: PropTypes.string,
  masterEvent: PropTypes.object,
  friendsFilter: PropTypes.string,
  t: PropTypes.func
};

export const MasterLandingPage = connectStream({
  courses: fetchCourse,
  following: () => getAthleteFollowingStream(getTokenRacerId()),
  masterEvent: ({masterId}) => getMasterEventStream(masterId),
  selectedEventStatus: ({eventId, masterId}) => getEventStatus('ERP-current-event', {eventId, masterId}),
  eventOptions: ({masterId}) => getEventOptions(masterId),
  athlete: () => getAthleteStream(getTokenRacerId()),
  heroImage: ({masterId}) => getHeroImage(masterId)
})(PureMasterLandingPageComponent);
