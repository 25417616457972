import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import _ from 'lodash';
import {connect} from 'react-redux';
import ArrowDown from 'react-icons/lib/fa/arrow-down';
import ArrowUp from 'react-icons/lib/fa/arrow-up';
import {splitTable as style} from './styles';
import RankUtils from '../../../utils/races/RankUtils';
import {convertPace, convertTime, getPaceFromUnitType} from '@eventops/athlinks-lib-units';
import {
  getOverallBracket,
  getGenderBracket,
  getDivisionBracket,
  showPace
} from '../../../utils/IRPUtil';

const mapStateToProps = (state) => ({
  unitType: state.userPrefs.globalUnitType,
});

const IRPRaceDetailsComponent = (props) => {
  const {
    intervals,
    t
  } = props;

  if(!intervals.length) {
    return (
      <div>
        {t('No split details available.')}
      </div>
    );
  }

  return renderRaceDetails(props);
};

IRPRaceDetailsComponent.propTypes = {
  intervals: PropTypes.array,
  isMobile: PropTypes.bool
};

IRPRaceDetailsComponent.defaultProps = {
  intervals: [],
  isMobile: false,
};

/***********************************************************************************
 * UTILITY FUNCTIONS
 ***********************************************************************************/

function getBracketWithDelta(bracket, prevBracket) {
  return bracket && prevBracket && bracket.rank && prevBracket.rank ?
    { ...bracket, delta: bracket.rank - prevBracket.rank }
    : { ...bracket, delta: 0 };
}

/***********************************************************************************
 * TABLE HEADER AND ROWS
 ***********************************************************************************/
const header = (props) => {
  const {
    isMobile = false,
    t
  } = props;

  const colClass = isMobile ? 'col-3' : 'col';

  return (
    <div className='row mx-0' style={style.tableHead(isMobile)} id="race-details">
      <div
        className={isMobile ? 'col-3 px-0' : 'col px-0'}
        style={{textAlign: 'left'}}>
        {t('split')}
      </div>
      <div className={colClass}>{t('Overall')}</div>
      {!isMobile && <div className='col'>{t('Gender')}</div>}
      {!isMobile && <div className='col'>{t('Division')}</div>}
      <div className={colClass}>{t('Pace')}</div>
      <div className={colClass}>{t('Time')}</div>
    </div>
  )
};

const renderRaceDetails = (props) => {
  const {
    intervals,
    isMobile,
    t
  } = props;

  const splits = [...intervals.filter((interval) => !interval.intervalFull), ...intervals.filter((interval) => interval.intervalFull)];

  const splitRows = splits.map((split, i) => {
    const prevSplit = i > 0 ? splits[i - 1] : [];
    const prevBrackets = prevSplit.brackets || [];
    const brackets = split.brackets || [];
    const hasPace = showPace(split.pace);
    const hasTime = _.get(split, 'timeWithPenalties.timeInMillis', 0) > 0;

    const newPace = hasPace && !!split?.pace?.distance
      ? convertPace(getPaceFromUnitType(split.pace, props.unitType), {capitalizeLabel: false})
      : {pace: '--'}

    return renderRow('split_' + i, {
      intervalName: split.intervalName,
      brackets: brackets.map((bracket, i) => getBracketWithDelta(bracket, prevBrackets[i])) || [],
      pace: newPace,
      time: hasTime ? convertTime(_.assign({}, split.timeWithPenalties, {timeUnit: 'h'})) : {value: '--'}
    },
      isMobile,
      t
    )
  });

  return (
    <div>
      {header({isMobile, t})}
      {splitRows}
    </div>
  );
};



const renderRow = (key, {
  intervalName,
  brackets,
  pace,
  time,
}, isMobile, t) => {
  const className = isMobile ? 'col-3' : 'col';

  const overallBracket = getOverallBracket(brackets);
  const genderBracket = getGenderBracket(brackets);
  const divisionBracket = getDivisionBracket(brackets);

  return [
    <div key={key} className='row mx-0' style={style.header}>
      <div
        className={isMobile ? 'col-3' : 'col'}
        style={style.leftDatum(isMobile)}>
        {intervalName}
      </div>
      <div key={key + '_overall'} className={className}>
        <TdRank
          styleProp={style.datum(isMobile)}
          isMobile={isMobile}
          t={t}
          {...overallBracket}
        />
      </div>
      {
        !isMobile ?
        <div key={key + '_gender'} className={className}>
          <TdRank
            styleProp={style.datum(isMobile)}
            isMobile={isMobile}
            t={t}
            {...genderBracket}
          />
        </div> : null
      }
      {
        !isMobile ?
        <div key={key + '_age'} className={className}>
          <TdRank
            styleProp={style.datum(isMobile)}
            isMobile={isMobile}
            t={t}
            {...divisionBracket}
          />
        </div> : null
      }
      <div className={className}>
        <TdPace
          styleProp={style.datum(isMobile)}
          isMobile={isMobile}
          {...pace}
        />
      </div>
      <div className={className} style={style.time(isMobile)}>
        {time.value}
      </div>
    </div>
  ];
};

/***********************************************************************************
 * FUNCTIONAL TABLE ELEMENTS
 ***********************************************************************************/
const TdRankComponent = ({styleProp, rank, totalInBracket, totalAthletes, delta, isMobile, t}) => {
  if (!rank || RankUtils.isDnf(parseInt(rank)) || RankUtils.isNoRank(rank)) {
    return (
      <div style={styleProp}>--</div>
    );
  }
  return (
    <div style={styleProp}>
      <div style={style.rankNumerator(isMobile)}>
        {rank}
        {delta < 0 ?
          <ArrowUp style={style.rankArrow}/>
        : delta > 0 ?
          <ArrowDown style={style.rankArrow}/>
        : null}
      </div>
      <div style={style.paceDenominator}>
        {t('of {{count}}', {count: totalInBracket && totalInBracket < totalAthletes ? totalInBracket : totalAthletes})}
      </div>
    </div>
  );
};

const TdRank = withTranslation()(TdRankComponent);

const TdPace = ({styleProp, value, label, isMobile}) => {
  return (
    <div style={styleProp}>
      <div style={style.paceNumerator(isMobile)}>
        {value || '--'}
      </div>
      <div style={style.paceDenominator}>
        {label || ''}
      </div>
    </div>
  )
};

export const IRPRaceDetails = connect(mapStateToProps)(IRPRaceDetailsComponent)
