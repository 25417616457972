import { EventResultsComponent } from './EventResultsComponent'
import { EventResultsProps } from '../../utils/types'
import { useGetRaceLeaderboardResultsQuery } from '../../api/reigniteApi'
import transformReigniteRaceLeaderboardResults from '../../utils/transformReigniteRaceLeaderboardResults'
import useSse from '../../lib/firebase/useSse'
import { ReigniteEventSseResults } from './ReigniteEventSseResults'

export function ReigniteRaceLeaderboardResults(
  props: EventResultsProps & {
    athlinksEventId: number
    divisionId?: number
    eventCourseId: number
    from: number
    isLive: boolean
    limit: number
    selectedEvent?: {
      event?: {
        ctliveId?: number
      }
    }
  }
) {
  const {
    athlinksEventId,
    divisionId,
    eventCourseId,
    from,
    isLive,
    limit,
  } = props

  const usingSse = useSse(athlinksEventId) && isLive
  const {
    currentData: data,
    isError,
    isFetching,
    refetch,
  } = useGetRaceLeaderboardResultsQuery(
    {
      eventId: athlinksEventId,
      eventCourseId,
      divisionId: divisionId || 0,
      from,
      limit,
    },
    {
      pollingInterval: isLive && !usingSse
        ? 30_000
        : undefined,
      skip: !eventCourseId,
    }
  )
  const isLoading = isFetching && !data

  const mapped = data
    && data.intervals.some(x => x.results.length)
    ? transformReigniteRaceLeaderboardResults({
      data,
      eventId: athlinksEventId,
      isLinked: !!props.selectedEvent?.event?.ctliveId,
    })
    : []

  return (
    <>
      {usingSse && <ReigniteEventSseResults
        athlinksEventId={athlinksEventId}
        divisionId={divisionId}
        eventCourseId={eventCourseId}
        refetch={refetch}
      />}
      <EventResultsComponent
        {...props}
        fetchEventResultsError={isError}
        eventResults={{
          data: mapped,
          isLoading,
        }}
        isFetchingEventResults={isFetching}
        refetch={refetch}
        useRtkq={true}
      />
    </>
  )
}
