import { InternalClientActionType } from "../types"
import { ConnectionTestState } from "../model/connectionTestState"

export default function connectionTestReducer(
  state = new ConnectionTestState(),
  action
) {
  switch (action.type) {
    case InternalClientActionType.TEST_CONNECTION:
      return {
        ...state
      }
    case InternalClientActionType.TEST_CONNECTION_COMPLETE:
      return {
        ...state,
        connectionSuccesful: true
      }
    default:
      return state
  }
}
