import React from 'react';
import { Grid } from '@material-ui/core';
import { Pager } from '../../components/shared/Pager';
import { RosterTable } from './RosterTable';
import { RosterEntry } from '../../types.rosters';

type Props = {
  isLoading: boolean,
  isMobile: boolean,
  roster?: {
    page: number,
    pageSize: number,
    results: RosterEntry[]
    total: number
  },
  t: any,
}
export function RosterGrid({
  isLoading,
  isMobile,
  roster,
  t,
}: Props) {
  if (isLoading || !roster?.results?.length)
    return null;
  const { page, pageSize, total } = roster
  return (
    <>
      <Grid item xs={12}>
        <RosterTable
          isMobile={isMobile}
          pageSize={pageSize}
          roster={roster.results}
          t={t} />
      </Grid>
      <Grid item xs={12} container justify={'center'} style={{ marginTop: 16 }}>
        <Pager
          pageNumber={page} // zero-based
          pageSize={pageSize}
          total={total} />
      </Grid>
    </>
  );
}
