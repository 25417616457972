import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { DateTime } from "../../components/shared/DateTime";
import { _date, isInFuture } from "../../utils/date";
import { trackGoogleEvent } from "../../utils/googleEvents";

import { useStyles } from "./styles";

interface LearnMoreOrRegisterButtonProps {
  CTLiveLink: string;
  masterId: number;
  realURL?: string;
  startTime: string
}

export const LearnMoreOrRegisterButton = ({
  CTLiveLink,
  masterId,
  realURL,
  startTime,
}: LearnMoreOrRegisterButtonProps) => {
  const forceRegisterHideArray = [115192];
  const forceHidingRegisterButton = forceRegisterHideArray.includes(masterId);

  const { t } = useTranslation();
  const styles = useStyles();

  const isInFutureVal = isInFuture(startTime);

  const showRegisterButton = CTLiveLink && isInFutureVal && !forceHidingRegisterButton;
  const showLearnMoreButton = !showRegisterButton && !!realURL;

  if (!showRegisterButton && !showLearnMoreButton) {
    return null;
  }

  return (
    <Box className={styles.actionButtonsContainer}>
      {showRegisterButton && (
        <>
          <Typography className={styles.actionButtonNote}>
            Next Event
            <br/>
            <DateTime
              date={_date(startTime)}
              showTime={false}
              icon={null}
            />
          </Typography>

          <a
            href={CTLiveLink}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => trackGoogleEvent('Race', 'Click', 'Register')}
            className={styles.actionButtonLinkRegister}
          >
            {t('REGISTER')}
          </a>
        </>
      )}

      {showLearnMoreButton && (
        <a
          href={realURL}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackGoogleEvent('Advertisements', 'Click', 'Learn More')}
          className={styles.actionButtonLinkLearnMore}
        >
          {t('LEARN MORE')}
        </a>
      )}
    </Box>
  );
};