import { withTranslation , Trans} from 'react-i18next';
import React from 'react';
import {summaryStyles} from './styles';
import {Weather} from '../../weather/Weather';
import _ from 'lodash';
import {connect} from 'react-redux';
import { UnitType } from '@eventops/athlinks-lib-units';

const mapStateToProps = (state) => ({
  unitType: state.userPrefs.globalUnitType,
})

const RaceSummaryComponent = ({distance, unitType, startTime, event, altTime = '', isGunTime = false, t, thirdParty}) => {
  const alternateTimeMessage = isGunTime ? t('Chip time') : t('Gun time');
  const timeStart = _.get(startTime, '[0]', '');
  const timePeriod = _.get(startTime, '[1]', '');
  const timeZone = _.get(startTime, '[2]', '');

  const distanceLabel = unitType === UnitType.METRIC ? t('Kilometers') : t('Miles')

  return (
    <div style={summaryStyles.summaryContainer}>
      <div style={summaryStyles.summaryTitle}>
        {t('Race Summary')}
      </div>
      <div>
        {
          startTime && thirdParty &&
          <div style={summaryStyles.row}>
            <div className="col-5 px-0" style={summaryStyles.labelColumn}>
              {
                isGunTime
                  ? t('Gun Start Time')
                  : t('Chip Start Time')
              }
            </div>
            <div className="col-7" style={summaryStyles.valueColumn}>
              {timeStart}
              <span style={summaryStyles.valueUnits}>
                {` ${timePeriod} ${timeZone}`}
              </span>
            </div>
          </div>
        }
        <div style={summaryStyles.row}>
          <div className="col-5 px-0" style={summaryStyles.labelColumn}>
            {t('Distance')}
          </div>
          <div className="col-7" style={summaryStyles.valueColumn}>
            <Trans>
              {{distance}}
              <span style={summaryStyles.valueUnits}> {distanceLabel}</span>
            </Trans>
          </div>
        </div>
        {
          altTime && altTime != '0:00' && thirdParty &&
          <div style={summaryStyles.row}>
            <div className="col-5 px-0" style={summaryStyles.labelColumn}>
              {alternateTimeMessage}
            </div>
            <div className="col-7" style={summaryStyles.valueColumn}>
              {altTime}
            </div>
          </div>
        }
        <div style={summaryStyles.lastRow}>
          <div className="col-5 px-0" style={summaryStyles.labelColumn}>
            {t('Weather')}
          </div>
          <div className="col-7" style={Object.assign({}, summaryStyles.valueColumn, {lineHeight: '2em'})}>
            <Weather event={event} type="resultsSummary"/>
          </div>
        </div>
      </div>
    </div>
  )
};

const raceSummaryComponentConnected = connect(mapStateToProps)(RaceSummaryComponent);

export const RaceSummary = withTranslation()(raceSummaryComponentConnected);
