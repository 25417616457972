import {
  MiddlewareAPI,
  Action
} from 'redux'
import {
  configureStore as createStore
} from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import rootReducer from '../reducers'
import rootResultsReducer from '../resultsClient/reducers'
import rootResultsEpic from '../resultsClient/epics'
import { configs } from '../configs'
import { combineEpics, createEpicMiddleware } from 'redux-observable'
import { getUserPrefsEpic } from '../epics/getUserPrefsEpic'
import { setUserPrefsEpic } from '../epics/setUserPrefsEpic'
import { mapCheckEpic } from '../epics/mapCheckEpic'
import { configureResultsClient } from '../resultsClient/config'
import { Log } from '../logger'
import {
  externalToInternalTypeMap,
  internalToExternalTypeMap
} from '../resultsClient/actionMapper'
import { alaskaApi } from '../api/alaskaApi'
import { reigniteApi } from '../api/reigniteApi'
import { resultsApi } from '../api/resultsApi'
import { finisherCertificateApi } from '../api/finisherCertificateApi'

let externalStore: MiddlewareAPI<any> | undefined = undefined
let internalStore: MiddlewareAPI<any> | undefined = undefined

const internalResultsMiddleWare =
  (store: MiddlewareAPI<any>) => {
    internalStore = store
    Log.debug('SETTING INTERNAL STORE TO: ', internalStore)
    return (next: (action: Action<any>) => void) =>
      (action: Action<any>) => {
        Log.debug('INTERNAL ACTION IS:', action)
        const internalActionType = action.type
        Log.debug('INTERNAL ACTION TYPE IS: ', internalActionType)
        const newExternalAction = internalToExternalTypeMap[internalActionType]
        Log.debug('NEW EXTERNAL ACTION IS: ', newExternalAction)
        /**
         * In the event of integration tests, the middleware will not have
         * an external store
         */
        if (newExternalAction && externalStore) {
          externalStore.dispatch(newExternalAction(action))
        }
        else {
          Log.warn('NO EXTERNAL DISPATCH', newExternalAction, externalStore)
        }
        next(action)
      }
  }

const resultsMiddleWare =
  (store: MiddlewareAPI<any>) => {
    externalStore = store
    Log.debug('SETTING EXTERNAL STORE TO: ', externalStore)
    return (next: (action: Action<any>) => void) =>
      (action: Action<any>) => {
        Log.debug('EXTERNAL ACTION IS:', action)
        const externalActionType = action.type
        Log.debug('EXTERNAL ACTION TYPE IS: ', externalActionType)
        const newInternalAction = externalToInternalTypeMap[externalActionType]
        Log.debug('NEW INTERNAL ACTION IS: ', newInternalAction)
        if (newInternalAction) {
          internalStore?.dispatch(newInternalAction(action))
        }
        else {
          Log.warn('NO INTERNAL DISPATCH', newInternalAction, externalStore)
        }
        next(action)
      }
  }

configureResultsClient(`${configs.resultsApiUrl}`, configs.resultsHubUrl);

export const configureInternalStore = () => {
  const epicMiddleware = createEpicMiddleware()
  console.log('running epics')
  const store = createStore({
    reducer: rootResultsReducer,
    middleware: get => get()
      .concat(thunk)
      .concat(epicMiddleware)
      .concat(internalResultsMiddleWare)
  })
  epicMiddleware.run(rootResultsEpic)
  return store
}

export const configureStore = () => {
  const epicMiddleware = createEpicMiddleware()
  const store = createStore({
    reducer: rootReducer,
    middleware: get => get()
      .concat(alaskaApi.middleware)
      .concat(finisherCertificateApi.middleware)
      .concat(reigniteApi.middleware)
      .concat(resultsApi.middleware)
      .concat(resultsMiddleWare)
      .concat(epicMiddleware)
  })
  epicMiddleware.run(
    combineEpics(
      getUserPrefsEpic,
      setUserPrefsEpic,
      mapCheckEpic,
    )
  )
  return store
}
