import { InternalClientActionType } from "./types";
import { ResultActionTypes } from "./types";

export const getMediaInternal = (
  action
) => (
    {
      ...action,
      type: InternalClientActionType.GET_MEDIA_DATA
    }
  )

export const getMedia = (
  args,
  pollingInterval
) => ({
  type: ResultActionTypes.GET_MEDIA,
  args,
  pollingInterval
})

export const getMediaError = (
  action
) => (
    {
      ...action,
      type: ResultActionTypes.GET_MEDIA_ERROR
    }
  )

export const getMediaErrorInternal
  = (
    args,
    url,
    error
  ) => ({
    type: InternalClientActionType.GET_MEDIA_ERROR,
    error,
    args,
    url
  })

export const getMediaStartedInternal = (args) => (
  {
    type:
      InternalClientActionType.GET_MEDIA_DATA_STARTED_POLLING,
    args
  }
)

export const getMediaStoppedInternal = (
  args
) => (
    {
      type: InternalClientActionType.GET_MEDIA_DATA_STOPPED_POLLING,
      args
    }
  )

export const getMediaSubscribed = (
  action
) => (
    {
      ...action,
      type: ResultActionTypes.GET_MEDIA_SUBSCRIBED
    }
  )

export const getMediaUpdate = (
  action
) => (
    {
      ...action,
      type: ResultActionTypes.GET_MEDIA_UPDATE
    }
  )

export const getMediaUpdateInternal
  = (
    results,
    args
  ) =>
    ({
      type: InternalClientActionType.GET_MEDIA_DATA_UPDATE,
      results,
      args
    })
