import React from 'react';
import { Select } from '@material-ui/core';
import { IPushHistory } from './usePushHistory';

export function EventSelect({
  eventId,
  pushHistory,
  races,
}: {
  eventId: number
  pushHistory: IPushHistory
  races: any[]
}) {
  const setEventId = (evid: any) => {
    pushHistory({ evid, _page: 1 });
  };
  const eventSelect = React.useRef();

  return (
    <Select
      defaultValue={eventId}
      fullWidth
      native
      ref={eventSelect}
      onChange={(ev) => setEventId(ev.target.value)}
      inputProps={{ id: 'event-select' }}
    >
      {races?.sort((first, second) => {
        const fdate = Date.parse(first.raceDate);
        const sdate = Date.parse(second.raceDate);
        return fdate > sdate ? 1 : sdate > fdate ? -1 : 0;
      }).map((x) => (
        <option key={x.raceID} value={x.raceID}>{new Date(x.raceDate).toLocaleDateString()}</option>
      ))}
    </Select>
  );
}
