import { IRPBodyContainerComponent } from "../../components/IRP/body/IRPBodyContainer"
import useIrpMedia from "../../hooks/useIrpMedia"
import useFinisherCertificate from "./useFinisherCertificate"

export const ResultsHubIndividualResult = (props: {
  athlinksEventId: number
  bib?: string
  entryId?: number
  event?: {
    ctliveId?: number
  }
  eventCourseId: number
  irpResult?: {
    thirdPartyEntryId: number
  }
}) => {
  const {
    athlinksEventId,
    bib,
    entryId,
    eventCourseId,
  } = props

  const thirdPartyEntryId = props.irpResult?.thirdPartyEntryId ?? 0
  const thirdPartyEventId = props.event?.ctliveId ?? 0
  const media = useIrpMedia({
    thirdPartyEntryId,
    thirdPartyEventId,
  })
  const certificate = useFinisherCertificate({
    athlinksEventId,
    bib,
    entryId,
    eventCourseId,
    thirdPartyEntryId,
  })

  return <IRPBodyContainerComponent
    {...props}
    certificate={certificate}
    media={media}
  />
}