import {colors, fonts} from '../../../shared/styles'

export const styles = {
  desktop: {
    actionsContainerStyle: {
      padding: '0',
    },
    overlayStyle: {
      padding: 15,
    },
    bodyStyle: {
      color: colors.black,
      fontSize: '16px',
      lineHeight: '22px'
    },
    contentStyle: {
      padding: '0',
      minWidth: '740px',
      maxWidth: '800px'
    },
    titleContainerStyle: {
      padding: '0',
    },
    titleStyle: {
      padding: '0px 36px 4px 36px',
      fontSize: '35px',
    }
  },
  mobile: {
    actionsContainerStyle: {
      padding: '0',
      marginTop: 0
    },
    overlayStyle: {
      padding: 15,
    },
    bodyStyle: {
      color: colors.black,
      fontSize: '1em',
      lineHeight: '1.5em',
      padding: '0 24px',
      overflowY: 'scroll'
    },
    contentStyle: {
      padding: '0',
      maxWidth: 600,
      width: 'calc(100vw - 25px)',
      margin: 12,
      marginBottom: 12,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    titleContainerStyle: {
      padding: '0'
    },
    titleStyle: {
      textAlign: 'center',
      marginBottom: '12px',
      fontSize: '1.5em'
    }
  },
  modalContainer: {
    modalBackdrop: {
      backgroundColor: 'rgba(0,0,0,0.3)',
      bottom: 0,
      padding: '0px 15px',
      position: 'fixed',
      top: 0,
      zIndex: 1040
    },
    modalBackdropContent: {
      overflowX: 'hidden',
      overflowY: 'scroll'
    },
    modalBodyNoScroll: {
      backgroundColor: colors.white,
      minHeight: 232
    },
    modalBodyScroll: {
      backgroundColor: colors.white,
      minHeight: 232,
      overflowX: 'hidden',
      overflowY: 'scroll'
    },
    modalCloseXButton: {
      backgroundColor: colors.white,
      border: 'none',
      borderRadius: 5,
      cursor: 'pointer',
      padding: '20px 20px 15px'
    },
    modalContent: (isMobile) => ({
      backgroundColor: colors.white,
      borderRadius: isMobile ? 'none' : 5,
      boxShadow: '0 3px 25px 0 rgba(0, 0, 0, 0.5)',
      height: isMobile ? '100%' : 500,
      marginLeft: isMobile ? 0 : null,
      marginRight: isMobile ? 0 : null,
      maxWidth: isMobile ? 'unset' : 680,
      minWidth: 325,
      padding: 0,
      width: '100%',
      zIndex: 1050
    }),
    modalSubtitle: {
      color: colors.darkType,
      fontFamily: fonts.default,
      fontSize: '14px',
      lineHeight: '19px',
      marginBottom: 20,
      marginTop: 8,
      padding: '8px 30px 35px',
      textAlign: 'center'
    },
    modalTitle: {
      color: colors.darkType,
      fontFamily: fonts.default,
      fontSize: '36px',
      fontWeight: 300,
      lineHeight: '44px',
      padding: '0px 30px',
      textAlign: 'center'
    },
  }
};
