import { setUserPrefsAction } from '../actions/userPrefsAction'
import { UnitType } from '@eventops/athlinks-lib-units'

const setGlobalUnitType = (globalUnitType, isGlobalUnitTypeFromConfig = false) => (dispatch) => dispatch(
  setUserPrefsAction({
    globalUnitType,
    isGlobalUnitTypeFromConfig
  })
)

export const setGlobalUnitTypeToMetric = setGlobalUnitType(UnitType.METRIC)
export const setGlobalUnitTypeToImperial = setGlobalUnitType(UnitType.IMPERIAL)
export const setGlobalUnitTypeToDefault = setGlobalUnitType(UnitType.DEFAULT)
