import { colors, textStyles } from '../../../shared/styles';

const unitTypeButtonWidth = '2.9375rem'

export const styles = {
  buttonHoverStyles: () => `
    .hover-unit-button:hover {
      background: #EEEEEE !important;
	}
  `,
	columnsWrapper: (isMobile) => ({
		paddingTop: 20,
		gap: '15px',
		display: isMobile ? 'block' : 'flex',
	}),
	leftColumn: {
		flex: '0 0 300px',
	},
	rightColumn: {
		flex: 1,
	},
	cardContainer: {
		marginBottom: 20,
    padding: '15px 20px 20px',
    backgroundColor: '#fff',
    boxShadow: colors.cardShadow
	},
	mapTrackingContainer: {
		marginBottom: 20,
    padding: '10px 20px',
		backgroundColor: 'rgb(22, 169, 225)',
    boxShadow: colors.cardShadow,
		borderRadius: 3,
		color: '#fff',
		fontWeight: 'bold'
	},
	mapTrackBetaBadge: {
		color: 'rgb(22, 169, 225)',
		padding: '5px 10px',
		borderRadius: 10,
		fontSize: 12,
		marginRight: 15,
		backgroundColor: 'white'
	},
	viewMapBtn: {
		padding: 10,
		color: 'white',
		float: 'right',
		border: '1px solid white',
		borderRadius: 3
	},
	backIcon: {
		width: 20,
		height: 20,
		color: colors.blue,
	},
	backText: {
		...textStyles.sectionHead,
		color: colors.blue,
	},

	cardSection: {
		marginBottom: 13,
	},
	cardSectionTitle: {
		...textStyles.subHead3,
		margin: '0px 0px 20px',
    fontWeight: 400
	},
	raceNameContainer: {
		paddingBottom: 17,
		whiteSpace: 'nowrap',
		width: '100%'
	},
	raceNameText: (isMobile) => ({
		paddingBottom: 3,
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		width: isMobile ? '65%' : '80%'
	}),
	imperialMetricToggle: {
		textAlign: 'right',
		position: 'absolute',
		right: 10
	},
	stickyButton: (isMobile) => ({
		position: 'fixed',
		bottom: 0,
		width: isMobile ? '92%' : 282,
		left: isMobile ? '' : '50%',
		marginLeft: isMobile ? '' : '-141px',
		zIndex: 10
	}),
	allResults: {
		textAlign: 'right',
		fontSize: 12,
		color: '#16A9E1'
	},
	eventNameContainer: {
		fontSize: 12,
		fontFamily: 'ProximaNovaRegular',
		alignItems: 'center',
		color: '#4A4A4A'
	},
	dateTime: {
		borderRight: '1px solid lightgrey',
		paddingRight: 10,
		marginRight: 10,
		color: '#9B9B9B'
	},
  claimDescription: {
	  fontSize: 12,
    color: colors.grey,
    textAlign: 'center',
    paddingTop: 8
  },
  claimTooltip: {
    display: 'inline',
    paddingLeft: 4
  },
  claimTooltipIcon: {
	  marginTop: '-2px'
  },
  imperialButtonStyles: {
    backgroundColor: '#FFFFFF',
    border: '1px solid rgb(221, 221, 221)',
    borderRadius: '5px 0px 0px 5px',
    color: 'rgb(155, 155, 155)',
    cursor: 'pointer',
    fontWeight: 'bold',
    marginRight: 0,
    outline: 'none',
    padding: '7px 14px',
    width: unitTypeButtonWidth
  },
  metricButtonStyles: {
    backgroundColor: '#FFFFFF',
    borderBottom: '1px solid rgb(221, 221, 221)',
    borderLeft: '0px',
    borderRadius: '0px 5px 5px 0px',
    borderRight: '1px solid rgb(221, 221, 221)',
    borderTop: '1px solid rgb(221, 221, 221)',
    color: 'rgb(155, 155, 155)',
    cursor: 'pointer',
    fontWeight: 'bold',
    marginLeft: 0,
    outline: 'none',
    padding: '7px 14px',
    width: unitTypeButtonWidth
  },
  metricImperialActiveButton: {
    backgroundColor: 'rgb(22, 169, 225)',
    color: 'rgb(255, 255, 255)',
  },
  VRLinkContainer: {
		width: '100%',
		verticalAlign: 'middle',
		marginTop: 0,
		marginBottom: 25,
		paddingLeft: 20,
		paddingRight: 20
  },
  VRBanner: (isMobile) => ({
		height: 70,
		backgroundColor: '#16A9E1',
		width: '100%',
		color: '#FFFFFF',
		fontFamily: 'ProximaNovaBold',
		fontSize: isMobile ? 14 : 16,
		marginBottom: 20,
		borderRadius: 3,
		paddingLeft: isMobile ? 15 : 20,
		paddingTop: 15,
		paddingBottom: 15,
		lineHeight: '20px',
		boxShadow: '0 4px 7px 0 rgba(0,0,0,0.10)',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		transition: 'background box-shadow 0.2s'
  }),
  VRBannerSubtext: (isMobile) => ({
		fontFamily: 'ProximaNovaRegular',
		fontSize: isMobile ? 12 : 14
  }),
  VRBannerButtonText: (isMobile) => ({
		fontFamily: 'ProximaNovaBold',
		fontSize: isMobile ? 12 : 14,
		minWidth: isMobile ? 80 : '',
		textAlign: isMobile ? 'right' : '',
		textTransform: 'uppercase',
		verticalAlign: 'middle',
		lineHeight: '40px'
  }),
  VRBannerChevron: (isMobile) => ({
		fontSize: isMobile ? 12 : 14,
		justifyContent: 'center',
		marginTop: 9,
		width: 18,
		marginLeft: 10
  })
};

export const summaryStyles = {
	summaryContainer: {
		marginBottom: 45,
	},
	summaryTitle: {
		paddingBottom: 8,
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		paddingBottom: 8,
		marginBottom: 10,
		borderBottom: '1px solid '+ colors.greyLine,
	},
	lastRow: {
		display: 'flex',
		alignItems: 'center',
	},
	labelColumn: {
		...textStyles.sectionHead,
	},
	valueColumn: {
		fontSize: 21,
		lineHeight: '1em',
		fontFamily: 'LatoRegular',
		letterSpacing: -.75
	},
	valueUnits: {
		fontSize: 14,
		lineHeight: '1em',
		color: colors.grey,
		fontFamily: 'ProximaNovaRegular'
	}
};

export const splitTable = {
	title: {
		...textStyles.subHead3,
		fontWeight: '400',
		margin: '0 0 20px',
	},
	tableHead: () => ({
		...textStyles.sectionHead,
		paddingBottom: 5,
		textAlign: 'center',
	}),
	leftDatum: (isMobile) => ({
		padding: '15px 0',
		textAlign: 'left',
		fontSize: (isMobile) ? 12 : 18,
		color: colors.textColor,
	}),
	datum: () => ({
		textAlign: 'center',
		color: colors.textColor
	}),
	rankNumerator: (isMobile) => ({
    whiteSpace: 'nowrap',
    fontSize: isMobile ? 18 : 21,
	lineHeight: '1em',
	fontFamily: 'LatoBold',
	letterSpacing: -.75,
	}),
	rankArrow: {
		paddingBottom: 3,
		color: colors.grey,
		width: 13,
		height: 13
	},
	paceNumerator: (isMobile) => ({
		fontSize: (isMobile) ? 18 : 21,
		fontFamily: 'LatoBold',
		letterSpacing: -.75,
		lineHeight: '1em',
	}),
	paceDenominator: {
		fontSize: 11,
		color: colors.grey,
	},
	time: (isMobile) => ({
		paddingTop: (isMobile) ? 0 : 3,
		fontFamily: 'LatoBold',
		letterSpacing:(isMobile) ? -1.25 : -.75,
		fontSize: (isMobile) ? 20 : 24,
		lineHeight: '1em',
	}),
	header: {
		paddingBottom: 6,
		paddingTop: 6,
		alignItems: 'center',
		textAlign: 'center',
    borderTop: '1px solid '+ colors.greyLine
	},
	arrow: {
		paddingBottom: 3,
		color: '#9B9B9B',
		fontWeight: '100',
		width: 13,
		height: 13
  }
};
