import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connectStream, compose } from '../../lib/bastetjs/utils/connectStream';
import { injectIntl } from 'react-intl';
import { EventResultsStreams } from '../../data/EventResultsStreams';
import { EventStreams } from '../../data/EventStreams';
import { RaceStreams } from '../../data/RaceStreams';
import { getTokenRacerId } from '../../utils/isLoggedIn';
import { eventBus } from '../../data/Streams';
import { getExtraData } from '../../data/ExtraEventStream';
import { getAthleteRacesStream } from '../../data/AthleteStreams';
import { connect } from 'react-redux';
import { getNotifications } from '../../data/NotificationStreams';
import { useFirebaseConfig } from '../../lib/firebase/remote-config';
import { EventResultsProps } from '../../utils/types';
import useEventMetadata from '../../hooks/useEventMetadata';
import { EventResultsComponent } from './EventResultsComponent';
import useRtkq from '../../lib/firebase/useRtkq';
import { ReigniteEventResults } from './ReigniteEventResults';

const PureEventResults = (props: EventResultsProps) => {
  const athlinksEventId = props.eventResultsFilters.date
  const eventMetadata = useEventMetadata(athlinksEventId)
  const usingRtkq = useRtkq();

  const firebaseConfig = useFirebaseConfig()
  if (!firebaseConfig.initialized) {
    // do not allow app to initialize results-hub if we don't have the remote config yet
    return null
  }

  if (!usingRtkq) {
    // if rtkq disabled then revert to using results-hub
    return <EventResultsComponent
      {...props}
      eventMetadata={eventMetadata}
      useRtkq={false}
    />
  }

  return <ReigniteEventResults
    {...props}
    eventMetadata={eventMetadata}
  />
}

const mapStateToProps = (state: any) => ({
  dispatch: undefined,
  eventMetadata: state.eventMetadata,
  eventResults: state.eventResults,
  eventResultsFilters: state.eventResultsFilters
});

export const EventResults = compose(
  connect(mapStateToProps),
  withTranslation(),
  injectIntl,
  connectStream({
    master: EventResultsStreams.getErpEvent,
    races: ({ masterId }: any) => RaceStreams.receiveAthlinksRacesByMasterId({ masterId }),
    selectedEvent: ({ eventId }: any) => EventStreams.getEventByEventId(eventId, undefined), // TODO: get this in meta call
    selectedEventStatus: ({ eventId, masterId }: any) => EventStreams.getEventStatus('ERP-current-event', { eventId, masterId }),
    search: () => eventBus.getTopic('event_results_search'),
    page: () => EventResultsStreams.getPage(),
    extra: ({ eventId }: any) => getExtraData(eventId),
    athleteRaces: () => getAthleteRacesStream(getTokenRacerId()),
    notifications: getNotifications
  }),
  withRouter,
)(PureEventResults);
