import { useGetEventResultsQuery } from '../../api/resultsApi'
import { EventResultsComponent } from './EventResultsComponent'
import { EventResultsProps } from '../../utils/types'
import useSse from '../../lib/firebase/useSse'
import { ReigniteEventSseResults } from './ReigniteEventSseResults'

export function RtkQueryEventResults(props: EventResultsProps & {
  athlinksEventId: number
  divisionId?: number
  eventCourseId?: number
  intervalId?: number
  from: number
  isLive: boolean
  limit: number
}) {
  const {
    athlinksEventId,
    divisionId,
    eventCourseId,
    intervalId,
    from,
    isLive,
    limit,
  } = props

  const usingSse = useSse(athlinksEventId) && isLive

  const {
    data: eventResults,
    isError,
    isFetching,
    refetch,
  } = useGetEventResultsQuery(
    {
      athlinksEventId,
      eventCourseId,
      divisionId,
      intervalId,
      from,
      limit,
    },
    {
      pollingInterval: isLive && !usingSse
        ? 30_000
        : undefined,
    }
  )

  return (
    <>
      {usingSse && <ReigniteEventSseResults
        athlinksEventId={athlinksEventId}
        divisionId={divisionId}
        eventCourseId={eventCourseId}
        intervalId={intervalId}
        refetch={refetch}
      />}
      <EventResultsComponent
        {...props}
        fetchEventResultsError={isError && !eventResults?.length}
        eventResults={{
          data: eventResults || [],
          isLoading: isFetching,
        }}
        isFetchingEventResults={isFetching}
        refetch={refetch}
        useRtkq={true}
      />
    </>
  )
}
