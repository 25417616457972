import React from 'react';
import PropTypes from 'prop-types';
import { styles } from './styles';
import _ from 'lodash';

export const Locations = (props) => {
  const {
    t
  } = props;

  const renderLocations = (locations) => {
    return locations.filter((location) => !!location.name)
  };

  const showLocations = () => {
    let locations = renderLocations(props.courses.locations);
    return _.sortBy(locations, 'id').map((location) => {
      const locationHref = `http://maps.google.com/?q=${location.latitude},${location.longitude}`;

      return (
        <a style={styles.locationLink} href={locationHref} target="_blank" rel="noopener noreferrer"
           key={location.id}
           className="ctlive-location"
        >
          <div style={styles.locationTitle}>
            {location.name}
          </div>
          <div>{location.address.street1 ? location.address.street1 : null}</div>
          <div>{location.address.street2 ? location.address.street2 : null}</div>
          <div>
            {location.address.city ? location.address.city + ', ' : null}
            {location.address.country ? location.address.country + ' ' : null}
            {location.address.postalCode ? location.address.postalCode : null}
          </div>
        </a>
      )
    });
  };

  const hasLocations = props.courses
    && props.courses.locations
    && props.courses.locations.length;

  if(!hasLocations) {
    return null;
  }

  return (
    <div style={styles.container}>
      <div style={styles.title}>
        {t('Locations')}
      </div>

      { showLocations(props) }

    </div>
  );

};

Locations.propTypes = {
  courses: PropTypes.object,
  t: PropTypes.func
};
