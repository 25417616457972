import { InternalClientActionType } from "./types";
import { ResultActionTypes } from "./types";

export const getMetaInternal = (action) => ({
  ...action,
  type: InternalClientActionType.GET_META_DATA
})

export const getMeta = (args) => ({
  args,
  type: ResultActionTypes.GET_META
})

export const getMetaComplete =
  (action) => ({
    ...action,
    type: ResultActionTypes.GET_META_COMPLETE
  })

export const getMetaCompleteInternal = (
  meta,
  args
) => ({
  type: InternalClientActionType.GET_META_DATA_COMPLETE,
  meta,
  args
})

export const getMetaError =
  (action) => ({
    ...action,
    type: ResultActionTypes.GET_META_ERROR
  })

export const getMetaErrorInternal = (
  args,
  url,
  error
) => {
  return {
    type: InternalClientActionType.GET_META_DATA_ERROR,
    error,
    args,
    url
  }
}
