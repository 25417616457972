import { InternalClientActionType } from "../types"

export function metaReducer(
  state = {
    fetching: undefined,
    data: undefined
  },
  action
) {
  switch (action.type) {
    case InternalClientActionType.GET_META_DATA_COMPLETE:
      return {
        fetching: false,
        data: action.meta
      }
    case InternalClientActionType.GET_META_DATA:
      return {
        ...state,
        fetching: true
      }
    default:
      return state
  }
}
