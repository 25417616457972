import { textStyles } from '../../../shared/styles';

export const styles = { 
  container: {
    marginBottom: 24,
  },
  title: {
    margin: '0 0 13px',
    ...textStyles.sectionHead,
  },
  locationTitle: {
    ...textStyles.body1Dark,
    lineHeight: '1.4em',
  },
  locationLink: {
    display: 'block',
    marginBottom: 13,
    ...textStyles.textLink2Blue,
    lineHeight: '1.3em',
  },
};
