import { InternalClientActionType } from "./types";
import { ResultActionTypes } from "./types";

export const getEventSummary =
  (args) => ({
    args,
    type: ResultActionTypes.GET_EVENT_SUMMARY
  })

export const getEventSummaryInternal = (
  action
) => ({
  ...action,
  type: InternalClientActionType.GET_EVENT_SUMMARY
})

export const getEventSummaryComplete = (
  action
) => ({
  ...action,
  type: ResultActionTypes.GET_EVENT_SUMMARY_COMPLETE
})

export const getEventSummaryCompleteInternal = (
  eventSummary,
  args
) => ({
  type: InternalClientActionType.GET_EVENT_SUMMARY_COMPLETE,
  eventSummary,
  args
})


export const getEventSummaryError = (
  action
) => ({
  ...action,
  type: ResultActionTypes.GET_EVENT_SUMMARY_ERROR
})

export const getEventSummaryErrorInternal = (
  args,
  url,
  error
) => {
  return {
    type: InternalClientActionType.GET_EVENT_SUMMARY_ERROR,
    error,
    args,
    url
  }
}
