import React from 'react';
import { Select } from '@material-ui/core';
import { IPushHistory } from './usePushHistory';

type Props = {
  eventCourseId: number
  eventCourses: any[]
  pushHistory: IPushHistory,
}

export function EventCourseSelect({
  eventCourseId,
  eventCourses,
  pushHistory,
}: Props) {
  const setEventCourseId = (ecid: any) => {
    pushHistory({ ecid, _page: 1 });
  };
  const eventCourseSelect = React.useRef();

  return <Select
    defaultValue={eventCourseId}
    fullWidth
    native
    ref={eventCourseSelect}
    onChange={(ev) => setEventCourseId(ev.target.value)}
    inputProps={{ id: 'course-select' }}
  >
    {eventCourses.map((x) => (
      <option key={x.eventCourseID} value={x.eventCourseID}>{x.courseName}</option>
    ))}
  </Select>;
}
