import { InternalClientActionType } from "../types"

export function individualResultsReducer(
  state = {
    fetching: undefined,
    data: undefined
  },
  action
) {
  switch (action.type) {
    case InternalClientActionType.GET_INDIVIDUAL_RESULTS_DATA:
    case InternalClientActionType.GET_INDIVIDUAL_RESULTS_DATA_STARTED_POLLING:
      return {
        ...state,
        fetching: true
      }
    case InternalClientActionType.GET_INDIVIDUAL_RESULTS_DATA_UPDATE:
      console.log('got here internal', action)
      return {
        ...state,
        data: action.result
      }
    case InternalClientActionType.GET_INDIVIDUAL_RESULTS_DATA_STOPPED_POLLING:
      return {
        ...state,
        fetching: false
      }
    default:
      return state
  }
}
