import {
  testConnectionComplete
} from '../testConnection'
import {ofType} from 'redux-observable'
import {mapTo} from 'rxjs/operators'
import { InternalClientActionType } from '../types'

export function testConnectionEpic(
  actions$
) {
  return actions$
    .pipe(
      ofType(InternalClientActionType.TEST_CONNECTION),
      mapTo(testConnectionComplete(true))
    )
}
