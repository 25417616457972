import i18next from './i18n';
import _ from 'lodash';
import { configs } from '../configs'
import { getApiEventResults } from '../resultsClient/getEventResults';
import { getEventResultsStop } from '../resultsClient/getEventResultsStop';
export const unsupportedPaceRaceTypes = ['multisport', 'duathlon', 'triathlon', 'triathlon & multisport'];

/**
 * Check if results are from CTLive.
 * Checking isCTLive flag is not good since results can come from athlinks
 * even if isCTLive is set to true.
 * So strategy is just to check if there is entryStatus prop in
 * first result row
 * @return {boolean}
 */
export function hasCTLiveResults(results) {
  if(results && results.length && results[0].entryStatus) {
    return !!results[0].entryStatus;
  }

  return false;
}

/**
 * Split current results set in two groups. Ranked and Unranked
 * @return {object} {ranked: [...], unranked: [...]}
 */
export function groupByRankType(results)
{
  return results.reduce((acc, result) => {
    const {entryStatus} = result;

    if (entryStatus === 'CONF') {
      acc.ranked.push(result);
    }
    else if (entryStatus === 'DNF' || entryStatus === 'DQ' || entryStatus === 'UNRANKED') {
      acc.unranked.push(result);
    }
    return acc;
  }, {ranked: [], unranked: []});
}

/**
 * Group results by interval id
 * @param {[]} results
 * @return {object} {intId1: {...}, intId2: {...}, ....}
 */
export function groupResultsByInterval(results) {
  return results.reduce((acc, result) => {
    const {intervalId, intervalName, distance, intervalFull} = result;

    let intervalResults = acc[intervalId] || {
      intervalFull,
      intervalName,
      distance,
      results: []
    };

    intervalResults.results.push(result);
    acc[intervalId] = intervalResults;

    return acc;
  }, {});
}

/**
 * Order results by distance from start.
 * @param {object} groupedResults Results grouped by interval id
 * @return {[]}
 */
export function orderByDistance(groupedResults) {
  return Object
    .keys(groupedResults)
    .map((intervalId) => groupedResults[intervalId])
    .sort(({distance: d1}, {distance: d2}) => d1 === d2 ? 0 : d1 > d2 ? -1 : 1)
}

export function isGunTime(brackets) {
  const overAllBracket = _.find(brackets, ({bracketName}) => (bracketName || '').toLowerCase() === 'overall') || {};
  const rankType = (overAllBracket.bracketRankType || '').toLowerCase();

  return rankType === 'gun';
}

export function showClaimLink(claimed, hasResultInEventCourse, entryId, thirdPartyEntryId, entryType) {
  return !claimed &&
    !hasResultInEventCourse &&
    (entryId || thirdPartyEntryId) &&
    entryType !== 'TEAM';
}

export function verifyGender(age, gender, t) {
  if (!gender || gender === 'NOT SPECIFIED') return t('genderAbrev#U').toUpperCase()
  const g = gender;
  const isTeam = ['M', 'F', 'MALE', 'FEMALE', 'RELAY', 'R', 'U', 'NB',
    'NONBINARY', 'NON BINARY', 'NON-BINARY']
    .indexOf(g.trim().toUpperCase()) === -1 && !age;

  if (isTeam) {
    return t('Team').toUpperCase()
  }
  if (g.length <= 2) {
    if (i18next.exists(`genderAbrev#${g.toUpperCase()}`)) {
      return t(`genderAbrev#${g.toUpperCase()}`).toUpperCase()
    } else {
      return g;
    }
  } else {
    const fixed = g.toUpperCase().replace(/-|\s/g, '')
    if (i18next.exists(`genderMap#${fixed}`)) {
      return t(`genderMap#${fixed}`).toUpperCase()
    } else {
      return g;
    }
  }
}

export function shouldShowRankings(entryStatus, intervalFull = false) {
  return !entryStatus || entryStatus === 'CONF' || (entryStatus === 'DNF' && !intervalFull);
}

export function getEventResults(dispatch, eventId, eventCourseId, divisionId, splitId, from, pageSize) {
  const calculatedFrom = eventCourseId ? from * pageSize : 0;
  const calculatedLimit = eventCourseId ? pageSize : configs.numEventWideLeaderboardResultsPerPage;
  dispatch(getApiEventResults(
    getResultsArgObject(eventId, eventCourseId, divisionId, splitId, calculatedFrom, calculatedLimit),
    configs.shortPollingTime
  ));
}

export function stopEventResults(dispatch, eventId, eventCourseId, divisionId, splitId, from, pageSize) {
  const calculatedFrom = eventCourseId ? from * pageSize : 0;
  const calculatedLimit = eventCourseId ? pageSize : 3;
  dispatch(getEventResultsStop(
    getResultsArgObject(eventId, eventCourseId, divisionId, splitId, calculatedFrom, calculatedLimit)
  ));
}

export function getResultsArgObject(eventId, eventCourseId, divisionId, intervalId, from, limit) {
  return _.assign(
    {
      eventId,
      fro: from,
      limit
    },
    eventCourseId ? {eventCourseId} : {},
    divisionId ? {divisionId} : {},
    intervalId ? {intervalId} : {}
  )
}
export function getCourseDetail(eventCourseId, eventMetadata) {
  const courseData = eventMetadata.eventCourseMetadata || [];
  return (_.find(courseData, (course) => course.eventCourseId === eventCourseId) || {});
}