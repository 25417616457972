import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { flatMap, catchError } from 'rxjs/operators'
import { waitForData } from './util'
import { formatParams } from './params'
import { InternalClientActionType } from "../types"
import {
  getEventSummaryCompleteInternal, 
  getEventSummaryErrorInternal
} from '../getEventSummary'

export function buildEventSummaryQueryString(
  action
) {
  const {
    eventId,
    searchTerm,
    fro,
    limit
  } = action.args
  return `event/${eventId}/searchSummary` +
    formatParams([
      { searchTerm },
      { fro, param: 'from' },
      { limit }
    ])
}



export function getEventSummaryEpic(
  actions$
) {
  return actions$.pipe(
    ofType(InternalClientActionType.GET_EVENT_SUMMARY),
    flatMap(action$ => {
      const { args } = action$
      return from(
        waitForData(
          args,
          buildEventSummaryQueryString(action$),
          getEventSummaryCompleteInternal,
          getEventSummaryErrorInternal
        )
      ).pipe(
        catchError(err => of(err))
      )
    }),
    catchError(err => of(err))
  )
}
