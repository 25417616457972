import React from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { TextField, IconButton, InputAdornment } from '@material-ui/core';
import { SearchIcon } from '../../components/shared/icons';

type IPushHistory = (value: {
  _term: string;
  _page: number;
}) => void;

type Props = {
  pushHistory: IPushHistory
  t: (value: string) => string
}

export function TermSearch({
  pushHistory,
  t,
}: Props) {
  const termInput = React.useRef<any>();
  const currentUrl = () => new URL(window.location.href);
  const searchTerm = currentUrl().searchParams.get('term') || '';
  const [term, _setTerm] = React.useState(searchTerm);

  const setTerm = ($term: string) => {
    const searchTerm = currentUrl().searchParams.get('term') || '';
    const _term = $term ? String($term).trim() : ''
    // console.log('setTerm', { term, $term, _term })
    if (_term !== searchTerm) {
      _setTerm(_term);
      pushHistory({ _term, _page: 1 });
    }
  };
  React.useMemo(() => _setTerm(searchTerm), [searchTerm]);

  return (
    <TextField
      fullWidth
      autoComplete={'off'}
      autoCorrect={'off'}
      placeholder={t('Search by bib, name or team')}
      defaultValue={term}
      inputRef={termInput}
      variant="outlined"
      value={term}
      inputProps={{ id: 'athlete-search-input' }}
      InputProps={{
        endAdornment: term && term.length ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear search input"
              onClick={() => {
                setTerm(termInput.current.value = '');
              }}
              edge="end"
            >
              <HighlightOffIcon />
            </IconButton>
          </InputAdornment>
        ) : null,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )
      }}
      onChange={(e) => _setTerm(e.currentTarget?.value)}
      onKeyUp={(ev) => {
        if (ev.key === 'Enter') {
          ev.preventDefault();
          setTerm(termInput.current.value);
          if (ev.target instanceof HTMLElement)
            ev.target.blur();
        }
      }} />
  );
}
