import { ResultActionTypes } from "./types";
import { InternalClientActionType } from "./types";

export const getIndividualResultsStopInternal = (
  action
) => (
    {
      ...action,
      type: InternalClientActionType.GET_INDIVIDUAL_RESULTS_DATA_STOP_POLLING
    }
  )

export const getIndividualResultsStop = (
  args
) => ({
  type: ResultActionTypes.GET_INDIVIDUAL_RESULTS_STOP,
  args
})
