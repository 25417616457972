import { ofType } from 'redux-observable'
import {
  getMetaErrorInternal,
  getMetaCompleteInternal
} from '../getMeta'
import { from, of } from 'rxjs'
import { flatMap, catchError } from 'rxjs/operators'
import { waitForData } from './util'
import { InternalClientActionType } from '../types'

export function buildMetaQueryString(
  action
) {
  return `metadata/event/${action.args.eventId}`
}

export function getMetaData(
  actions$
) {
  return actions$.pipe(
    ofType(InternalClientActionType.GET_META_DATA),
    flatMap(action$ => {
      const { args } = action$
      return from(
        waitForData(
          args,
          buildMetaQueryString(action$),
          getMetaCompleteInternal,
          getMetaErrorInternal
        )
      ).pipe(
        catchError(err => of(err))
      )
    }),
    catchError(err => of(err))
  )
}
