import { from, of } from 'rxjs'
import { ofType } from 'redux-observable'
import { flatMap, catchError } from 'rxjs/operators'
import { pollForData, stopPolling } from './util'

import { InternalClientActionType } from "../types"
import {
  getMediaErrorInternal,
  getMediaStartedInternal,
  getMediaStoppedInternal,
  getMediaUpdateInternal
} from '../getMedia'
import { formatParams } from './params'

export function buildMediaQueryString(
  action
) {
  const {
    eventCourseId,
    eventId,
    bib,
    ctEntryId: id
  } = action.args
  const params = id ?
    [{ id }] :
    [
      { bib },
      { eventCourseId }
    ]
  return `media/${eventId}` + formatParams(params)
}

export function startMediaPolling(
  actions$
) {
  return actions$.pipe(
    ofType(InternalClientActionType.GET_MEDIA_DATA),
    flatMap(
      action$ => {
        const { pollingInterval, args } = action$
        return from(
          pollForData(
            args,
            buildMediaQueryString(action$),
            pollingInterval,
            getMediaUpdateInternal,
            getMediaStartedInternal(args),
            getMediaErrorInternal
          )
        ).pipe(
          catchError(err => of(err))
        )
      }
    ),
    catchError(err => of(err))
  )
}

export function stopMediaPolling(
  actions$
) {
  return actions$.pipe(
    ofType(InternalClientActionType.GET_MEDIA_DATA_STOP_POLLING),
    flatMap(action$ =>
      from(stopPolling(
        action$.args,
        buildMediaQueryString(action$),
        getMediaStoppedInternal(action$.args),
        getMediaErrorInternal
      )).pipe(
        catchError(err => of(err))
      )
    ),
    catchError(err => of(err))
  )
}
