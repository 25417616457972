import {stripLeadingZero} from '../shared/util';
import {convertTime} from '@eventops/athlinks-lib-units';
import _ from 'lodash';
import { configs } from '../configs'
import { getIndividualResults as getApiIndividualResults } from '../resultsClient/getIndividualResults'

/**
 * returns object with pace and pace units
 * @param paceArr
 * @param result
 */
export function getPace(paceArr, result = {}) {
  const splitPace = (result.pace || '').split(' ');
  const pace = stripLeadingZero(_.get(paceArr, '[0]', splitPace[0] || ''));
  const paceUnits = _.get(paceArr, '[1]', splitPace[1] || result.paceUnit || '');

  return {pace, paceUnits};
}

export function getOgTags(result, badgeUrl, image, t) {
  const firstName = result.firstName || '';
  const lastName = result.lastName || '';
  const fullName = `${firstName} ${lastName}`;
  const eventName = result.raceName || '';
  const ogTitle = t('{{name}}\'s Race Results', {name: fullName});
  const ogDescription = t(
    'View {{name}}\'s race results at {{eventName}}',
    {name: firstName, eventName}
  );
  const ogBadgeUrl = _.get(badgeUrl, 'url', '');
  const resolvedImage =  ogBadgeUrl ? {image: ogBadgeUrl, imageHeight: 630, imageWidth: 1200} : image;

  return {ogTitle, ogDescription, ogImage: resolvedImage};
}

/**
 * Builds ranks array for latest interval
 * Only for CTLive results
 * @return {[]}
 */
export function getBracketResults(brackets = []) {
  return brackets.map((res) => {
    return {
      rank: res.rank,
      total: res.totalInBracket && res.totalInBracket < res.totalAthletes ? res.totalInBracket : res.totalAthletes,
      label: res.bracketName,
      type: res.bracketType
    }
  });
}

const placeholderRank = {value: '--'};

function findBracket(brackets, validTypes, usePrimary = false) {
  const foundBrackets = _.filter(brackets, (bracket) => validTypes.includes((bracket.bracketType || '').toLowerCase()));
  const resolvedBrackets = _.isEmpty(foundBrackets) ? [placeholderRank] : foundBrackets;

  if(usePrimary) {
    const foundPrimary = resolvedBrackets.find((bracket) => bracket.isPrimary);

    if(foundPrimary) {
      return foundPrimary;
    }
  }

  return resolvedBrackets[0];
}

export function getOverallBracket(brackets) {
  const validOverallTypes = ['OVERALL', 'overall', 'open'];

  return findBracket(brackets, validOverallTypes);
}

export function getGenderBracket(brackets) {
  const validGenderTypes = ['GENDER', 'sex', 'gender'];

  return findBracket(brackets, validGenderTypes);
}

export function getDivisionBracket(brackets) {
  const validDivisonTypes = ['AGE', 'age', 'other'];

  return findBracket(brackets, validDivisonTypes, true);
}

export function getLatestScoredInterval(intervals = []) {
  return intervals?.length > 1
    ? intervals.find((interval) => interval.mostRecentInterval) || {}
    : intervals[0] || {};
}

export function getFullInterval(intervals = []) {
  return intervals?.length > 1
    ? intervals.find((interval) => interval.intervalFull) || {}
    : intervals[0] || {};
}

export function isAthleteConfirmed(entryStatus) {
  return !entryStatus || entryStatus === 'CONF';
}

export function isDNF(entryStatus) {
  return _.isEqual(entryStatus, 'DNF');
}

export function isDQ(entryStatus) {
  return _.isEqual(entryStatus, 'DQ');
}

export function isUnranked(entryStatus) {
  return _.isEqual(entryStatus, 'UNRANKED');
}

export function shouldShowRankings(entryStatus) {
  return isAthleteConfirmed(entryStatus) || isUnranked(entryStatus);
}

export function showPace(pace) {
  return _.get(pace, 'time.timeInMillis', 0) > 0 &&
    _.get(pace, 'distance.distanceInMeters', 0) > 0 &&
    _.get(pace, 'paceType', '') !== 'notcalculated';
}

export function getTimeLabel(result, isGunTime, t) {
  const canShowTimeLabel = !!result.thirdPartyEntryId;

  return canShowTimeLabel ? isGunTime ? t('Time (Gun)') : t('Time (Chip)') : t('Time');
}

export function getTime(interval, gunTime) {
  if (!_.isEmpty(interval) && !_.isEmpty(interval.timeWithPenalties) && interval.timeWithPenalties.timeInMillis > 0) {
      return convertTime({timeInMillis: interval.timeWithPenalties.timeInMillis, timeUnit: 'h'}).value
  }
  if (!_.isEmpty(interval)) {
    if (gunTime && interval.gunTime) {
      return convertTime({timeInMillis: interval.gunTime.timeInMillis, timeUnit: 'h'}).value
    }
    else if (!gunTime && interval.chipTime) {
      return convertTime({timeInMillis: interval.chipTime.timeInMillis, timeUnit: 'h'}).value
    }
  }

  return '';
}

// TODO: get rid of this
export function resolveClaimButtonProps(athleteId, result, isMobile, isLive, eventInfo, resultLocation,
                                        loggedIn, isMe, claimStatus, alreadyClaimedInCourse, onClaimCallback, tickString, t) {
  const claimResultStyle = {
    width: '100%',
    maxWidth: 392,
    display: isMobile ? 'block' : 'inline-block',
    borderRadius: 3
  };

  const disabled = (athleteId && !isMe) || (alreadyClaimedInCourse && !isMe && claimStatus !== 'ClaimInReview');

  return {
    athleteId,
    age: result.age,
    gender: result.gender,
    fullName: result.displayName,
    entryId: result.entryId || 0,
    ctEntryId: result.thirdPartyEntryId || 0,
    bib: result.bib,
    eventCourseId: result.eventCourseId,
    isLoggedIn: loggedIn,
    isMobile,
    style: claimResultStyle,
    isClaimed: result.racerId,
    racerId: result.racerId || 0,
    isLive,
    isPublished: result.isPublished,
    finalTime: tickString,
    eventName: result.raceName,
    city: eventInfo.city,
    state: eventInfo.region,
    country: eventInfo.countryId,
    date: eventInfo.startEpoch * 1000,
    athleteLocation: resultLocation,
    disabled,
    claimStatus,
    alreadyClaimedInCourse,
    onClaimCallback,
    t
  };
}

export function getIndividualResults(dispatch, eventId, eventCourseId, bib, thirdPartyEntryId, athlinksEntryId) {
  dispatch(getApiIndividualResults(
      {
        eventId,
        eventCourseId,
        bib,
        thirdPartyEntryId,
        athlinksEntryId
      },
      configs.shortPollingTime
  ))
}
