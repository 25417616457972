import { connect, useDispatch } from 'react-redux'
import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { connectStream, compose } from '../../lib/bastetjs/utils/connectStream'
import { EventResultsStreams } from '../../data/EventResultsStreams'
import { getTokenRacerId } from '../../utils/isLoggedIn'
import { getAthleteRacesStream } from '../../data/AthleteStreams'
import { useGetEventCoursesQuery } from "../../api/alaskaApi"
import { getFollowersStream } from '../../data/ProfileStreams'
import { getPendingClaims } from '../../data/UnclaimedSearchStreams'
import { getEntryDetail } from '../../data/VRStreams'
import { RtkQueryIndividualResult } from './RtkQueryIndividualResult'
import useEventMetadata from '../../hooks/useEventMetadata'
import { ReigniteIndividualResult } from './ReigniteIndividualResult'
import useRtkq from '../../lib/firebase/useRtkq'
import useReigniteResults from '../../lib/firebase/useReigniteResults'
import { useFirebaseConfig } from '../../lib/firebase/remote-config'
import { distanceUnitToUnitType } from '../../utils/distanceUnitToUnitType'
import { setUserPrefsAction } from '../../actions/userPrefsAction'
import { getUserPrefs } from '../../utils/userPrefsStorageHelper'
import { ResultsHubIndividualResult } from './ResultsHubIndividualResult'

const PureIRPResult = (props: any) => {
  const athlinksEventId = props.event?.athlinksId
  const { data: eventCourses } = useGetEventCoursesQuery(athlinksEventId, {
    skip: !athlinksEventId
  })

  const dispatch = useDispatch()

  const bib = props.bib
  const entryId = props.entryId
  const eventCourseId = props.race?.eventCourseId
  const eventMetadata = useEventMetadata(athlinksEventId)
  const eventCourseMetadata = eventMetadata.data?.eventCourseMetadata?.find(x => x.eventCourseId === eventCourseId)
  const intervals = eventCourseMetadata?.metadata?.intervals || []
  const raceType = eventCourseMetadata?.raceType
  const distanceUnit = intervals.find(x => x.isFull)?.distanceUnit || intervals[0]?.distanceUnit || 'mi'
  const userPrefs = getUserPrefs()

  useEffect(() => {
    if (
      (!userPrefs?.globalUnitType || userPrefs?.isGlobalUnitTypeFromConfig)
      && raceType
      && distanceUnit
      && !['multisport', 'triathlon & multisport'].includes(raceType)
    ) {
      dispatch(
        setUserPrefsAction({
          globalUnitType: distanceUnitToUnitType(distanceUnit),
          isGlobalUnitTypeFromConfig: true,
        })
      )
    }
  }, [raceType, distanceUnit, userPrefs, dispatch])

  const usingReigniteResults = useReigniteResults(athlinksEventId)
  const usingRtkq = useRtkq()

  const firebaseConfig = useFirebaseConfig()
  if (!firebaseConfig.initialized) {
    // do not allow app to initialize results-hub if we don't have the remote config yet
    return null
  }

  if (!usingRtkq) {
    return <ResultsHubIndividualResult
      {...props}
      athlinksEventId={athlinksEventId}
      bib={bib}
      courses={eventCourses}
      entryId={entryId}
      eventCourseId={eventCourseId}
      eventMetadata={eventMetadata}
      result={props.irpResult}
    />
  }

  const isLive = props.isLive || false

  if (usingReigniteResults) {
    return <ReigniteIndividualResult
      {...props}
      athlinksEventId={athlinksEventId}
      bib={bib}
      courses={eventCourses}
      entryId={entryId}
      eventCourseId={eventCourseId}
      eventMetadata={eventMetadata}
      isLive={isLive}
    />
  }

  return <RtkQueryIndividualResult
    {...props}
    athlinksEventId={athlinksEventId}
    courses={eventCourses}
    eventCourseId={eventCourseId}
    eventMetadata={eventMetadata}
    bib={bib}
    entryId={entryId}
    isLive={isLive}
  />
}

const mapStateToProps = (state: any, props: any) => ({
  dispatch: undefined,
  eventMetadata: state.eventMetadata,
  irpResult: state.irpResult?.data || props.irpResult,
  mapTracking: state.mapTracking,
  unitType: state.userPrefs.globalUnitType,
})

export const IRPResult = compose(
  connect(mapStateToProps),
  withTranslation(),
  connectStream({
    friends: () => getFollowersStream('followers', getTokenRacerId()),
    splitsContent: () => EventResultsStreams.getLegacyEntryResult(),
    pendingClaims: getPendingClaims,
    athleteRaces: () => getAthleteRacesStream(getTokenRacerId()),
    entryDetail: ({ irpResult }: any) => getEntryDetail({
      eventCourseId: irpResult.eventCourseId,
      racerId: irpResult.racerId,
      entryUniqueId: irpResult.entryUniqueId,
    }),
  })
)(PureIRPResult)
