import { ResultActionTypes } from "./types";
import { InternalClientActionType } from "./types";

export const getIndividualResults = (
  args,
  pollingInterval
) => ({
  type: ResultActionTypes.GET_INDIVIDUAL_RESULTS,
  args,
  pollingInterval: +pollingInterval
})

export const getIndividualResultsInternal = (
  action
) => (
    {
      ...action,
      type: InternalClientActionType.GET_INDIVIDUAL_RESULTS_DATA
    }
  )

export const getIndividualResultsError = (
  action
) => (
    {
      ...action,
      type: ResultActionTypes.GET_INDIVIDUAL_RESULTS_ERROR
    }
  )

export const getIndividualResultsErrorInternal
  = (
    args,
    url,
    error
  ) => ({
    type: InternalClientActionType.GET_INDIVIDUAL_RESULTS_ERROR,
    error,
    args,
    url
  })

export const getIndividualResultsStartedInternal = (args) => (
  {
    type:
      InternalClientActionType.GET_INDIVIDUAL_RESULTS_DATA_STARTED_POLLING,
    args
  }
)

export const getIndividualResultsStoppedInternal = (
  args
) => (
    {
      type: InternalClientActionType.GET_INDIVIDUAL_RESULTS_DATA_STOPPED_POLLING,
      args
    }
  )

export const getIndividualResultsSubscribed = (
  action
) => (
    {
      ...action,
      type: ResultActionTypes.GET_INDIVIDUAL_RESULTS_SUBSCRIBED
    }
  )

export const getIndividualResultsUpdate = (
  action
) => {
  console.log('got here action')
  return {
    ...action,
    type: ResultActionTypes.GET_INDIVIDUAL_RESULTS_UPDATE
  }
}

export const getIndividualResultsUpdateInternal
  = (
    result,
    args
  ) => {
    console.log('got here internal action')
    return {
      type: InternalClientActionType.GET_INDIVIDUAL_RESULTS_DATA_UPDATE,
      result,
      args
    }
  }
