import React from 'react';
import { styles } from './styles';
import { Grid } from '@material-ui/core';
import { TermSearch } from './TermSearch';
import { IPushHistory } from './usePushHistory';

export function PageView({
  children,
  isMobile,
  pushHistory,
  t,
}: {
  children: any
  isMobile: boolean
  pushHistory: IPushHistory
  t: any
}) {
  return (
    <div className="container">
      <div style={isMobile ? styles.contentCardMobile : styles.contentCard}>
        <Grid container direction="row">
          <Grid item md={8} xs={12}>
            <h2>{t('Roster')}</h2>
          </Grid>
          <Grid item md={4} xs={12} style={{ padding: 16 }}>
            <Grid item xs={12} container direction="row">
              <TermSearch
                pushHistory={pushHistory}
                t={t} />
            </Grid>
          </Grid>
          {children}
        </Grid>
      </div>
    </div>
  );
}
