import { Theme, makeStyles } from '@material-ui/core';

import { colors, fonts } from '../../shared/styles';

const actionButtonLinkBase = {
  padding: '8px 16px', 
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.4px',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
} as const;

const useStyles = makeStyles<Theme>(({ palette, breakpoints }) => ({
  actionButtonsContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
  },
  actionButtonLinkRegister: {
    ...actionButtonLinkBase,
    backgroundColor: palette.brandColor,
    border: 'none',
    borderRadius: '2px',
    color: colors.white,
    fontWeight: 600,
  },
  actionButtonLinkLearnMore: {
    ...actionButtonLinkBase,
    border: `1px solid ${palette.grey[400]}`,
    borderRadius: '4px',
    color: palette.grey[600],
    transition: 'all 0.2s ease',

    '&:hover': {
      borderColor: palette.common.black,
      color: palette.common.black,
    },
  },
  actionButtonNote: {
    color: palette.grey[600],
    fontSize: '12px',
    lineHeight: '16px',
    textWrap: 'nowrap',
    ...fonts.default,
  },
  aboutPageContent: {
    display: 'flex',
    gap: '48px',
    padding: '12px',

    [breakpoints.up('sm')]: {
      padding: '12px 24px 36px',
    }
  },
  aboutPageSummaryWrapper: {
    borderBottom: `1px solid ${palette.grey[100]}`
  },
  aboutPageDistancesSection: {
    display: 'flex',
    gap: '16px',
    marginTop: '12px',

    '& > div': {
      flex: '1 1 auto',
      maxWidth: '50%',
    },

    [breakpoints.up('sm')]: {
      flexDirection: 'row',
      gap: '12px',
    }
  },
  aboutPageResultSectionMobile: {
    borderTop: `1px solid ${palette.grey[100]}`,
    display: 'block',
    padding: '12px 0 24px 0',
    margin: '0 12px',

    [breakpoints.up('sm')]: {
      display: 'none',
    }
  },
  aboutPageResultSectionDesktop: {
    display: 'none',

    [breakpoints.up('sm')]: {
      display: 'block',
      flex: 0,
    }
  },
  aboutPageStickyFooter: {
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    bottom: '0px',
    width: '100%',
    zIndex: 99,
    backgroundColor: 'white',
    borderTop: `1px solid ${colors.greyLine}`,
    padding: '10px',

    [breakpoints.up('sm')]: {
      display: 'none',
    }
  },
  masterPageWrapper: {
    backgroundColor: colors.lightGrey,
    WebkitTouchCallout:'none',
  },
  masterPageContainer: {
    borderBottom: `1px solid ${palette.grey[100]}`,
    margin: '45px auto 0',
    maxWidth: '960px',
    width: '100%',

    [breakpoints.up('sm')]: {
      margin: '50px auto 0',
      paddingTop: '25px',
    }
  },
}));

export { useStyles };
