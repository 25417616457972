import { InternalClientActionType } from "./types";

export const testConnection = () => ({
  type: InternalClientActionType.TEST_CONNECTION
})

export const testConnectionComplete = (
  success
) => ({
  type: InternalClientActionType.TEST_CONNECTION_COMPLETE,
  success
})
