import { from, of } from 'rxjs'
import { ofType } from 'redux-observable'
import { flatMap, catchError } from 'rxjs/operators'
import { pollForData, stopPolling } from './util'
import { isEmpty } from 'lodash'
import {
  getIndividualResultsErrorInternal,
  getIndividualResultsStartedInternal,
  getIndividualResultsStoppedInternal,
  getIndividualResultsUpdateInternal
} from '../getIndividualResults'
import { InternalClientActionType } from '../types'
import { formatParams } from './params'

export function findIdOrBib(args) {
  const {
    athlinksEntryId,
    thirdPartyEntryId,
    bib
  } = args

  if (thirdPartyEntryId) return { id: thirdPartyEntryId }
  if (!isEmpty(bib)) return { bib }
  if (athlinksEntryId) return { id: athlinksEntryId }
  throw new Error('You must specify at least one of the following: ' +
    '[ athlinksEntryId | thirdPartyEntryId | bib]')
}

export function buildIndividualResultsQueryString(
  action
) {
  const {
    eventId,
    eventCourseId
  } = action.args
  const {
    id,
    bib
  } = findIdOrBib(action.args)
  return 'individual' +
    formatParams(
      [
        { id },
        { bib },
        { eventId },
        { eventCourseId }
      ]
    )
}

export function startIndividualResultsPolling(
  actions$
) {
 console.log('startIndividualResultsPolling')
  return actions$.pipe(
    ofType(InternalClientActionType.GET_INDIVIDUAL_RESULTS_DATA),
    flatMap(
      action$ => {
        const { pollingInterval, args } = action$
        console.log('start IRP polling', args, pollingInterval)
        return from(
          pollForData(
            args,
            buildIndividualResultsQueryString(action$),
            pollingInterval,
            getIndividualResultsUpdateInternal,
            getIndividualResultsStartedInternal(args),
            getIndividualResultsErrorInternal
          )
        ).pipe(
          catchError(err => of(err))
        )
      }
    ),
    catchError(err => of(err))
  )
}

export function stopIndividualResultsPolling(
  actions$
) {
  return actions$.pipe(
    ofType(InternalClientActionType.GET_INDIVIDUAL_RESULTS_DATA_STOP_POLLING),
    flatMap(action$ =>
      from(stopPolling(
        action$.args,
        buildIndividualResultsQueryString(action$),
        getIndividualResultsStoppedInternal(action$.args),
        getIndividualResultsErrorInternal
      )).pipe(
        catchError(err => of(err))
      )
    )
  )
}
