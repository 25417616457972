import { EventResultsComponent } from './EventResultsComponent'
import { EventResultsProps } from '../../utils/types'
import { useGetEventWideLeaderboardResultsQuery } from '../../api/reigniteApi'
import transformReigniteRaceLeaderboardResults from '../../utils/transformReigniteRaceLeaderboardResults'
import useSse from '../../lib/firebase/useSse'
import { ReigniteEventSseResults } from './ReigniteEventSseResults'

export function ReigniteEventWideLeaderboardResults(
  props: EventResultsProps & {
    athlinksEventId: number
    from: number
    isLive: boolean
    limit: number
    selectedEvent?: {
      event?: {
        ctliveId?: number
      }
    }
  }
) {
  const {
    athlinksEventId,
    from,
    isLive,
    limit,
  } = props

  const usingSse = useSse(athlinksEventId) && isLive

  const {
    currentData,
    isError,
    isFetching,
    refetch,
  } = useGetEventWideLeaderboardResultsQuery(
    {
      eventId: athlinksEventId,
      from,
      limit,
    },
    {
      pollingInterval: isLive && !usingSse
        ? 30_000
        : undefined,
      skip: !athlinksEventId,
    }
  )
  const isLoading = isFetching && !currentData

  const mapped = currentData
    && currentData.some(data =>
      data.intervals?.some(interval => interval?.results?.length)
    )
    ? currentData.flatMap(data =>
      transformReigniteRaceLeaderboardResults({
        data,
        eventId: athlinksEventId,
        isLinked: !!props.selectedEvent?.event?.ctliveId,
      })
    )
    : []

  return (
    <>
      {usingSse && <ReigniteEventSseResults
        athlinksEventId={athlinksEventId}
        refetch={refetch}
      />}
      <EventResultsComponent
        {...props}
        fetchEventResultsError={isError}
        eventResults={{
          data: mapped,
          isLoading,
        }}
        isFetchingEventResults={isFetching}
        refetch={refetch}
        useRtkq={true}
      />
    </>
  )
}
