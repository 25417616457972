import * as ResultsHubClient from '@eventops/athlinks-results-hub-client'

let lambdaBaseUrl = undefined,
  resultsHubUrl = undefined,
  suppressSubErrors = false

export const suppressSubscriptionErrors = () => suppressSubErrors

export const resetUrls = () => {
  lambdaBaseUrl = undefined
  resultsHubUrl = undefined
}

export const baseUrl = () => {
  if (!lambdaBaseUrl) {
    throw new Error('You must set an endpoint for the results lambdas! ' +
      'see: https://gitlab.eventops.io/athlinks/Athlinks-API-Results#where-it-lives')
  }
  return lambdaBaseUrl
}

const stripTrailingSlash = (url) => url.replace(/\/$/, '')

export const configureResultsClient = (
  resultsApiHost,
  resultsHubHost,
  suppressDuplicateSubscriptionErrors = suppressSubErrors
) => {
  lambdaBaseUrl = stripTrailingSlash(resultsApiHost)
  resultsHubUrl = stripTrailingSlash(resultsHubHost)
  suppressSubErrors = suppressDuplicateSubscriptionErrors
  ResultsHubClient.configure(resultsHubUrl, {transports: ['websocket']})
}

export const subscribe = (
  url,
  callback,
  interval
) => {
  if (!resultsHubUrl) {
    throw new Error('You must specify a results-hub URL before subscribing! ' +
      'see: https://gitlab.eventops.io/athlinks/athlinks-results-client#configuring-the-client')
  }
  return ResultsHubClient.subscribe(url, callback, interval)
}
