import { EventResultsComponent } from './EventResultsComponent'
import { EventResultsProps } from '../../utils/types'
import { useGetRaceDivisionIntervalResultsQuery } from '../../api/reigniteApi'
import transformReigniteIntervalResults from '../../utils/transformReigniteIntervalResults'
import useSse from '../../lib/firebase/useSse'
import { ReigniteEventSseResults } from './ReigniteEventSseResults'

export function ReigniteRaceDivisionIntervalResults(
  props: EventResultsProps & {
    athlinksEventId: number
    divisionId: number
    eventCourseId: number
    intervalId: number
    from: number
    isLive: boolean
    limit: number
  }
) {
  const {
    athlinksEventId,
    divisionId,
    eventCourseId,
    intervalId,
    from,
    isLive,
    limit,
  } = props

  const usingSse = useSse(athlinksEventId) && isLive

  const {
    currentData: data,
    isError,
    isFetching,
    refetch,
  } = useGetRaceDivisionIntervalResultsQuery(
    {
      eventId: athlinksEventId,
      eventCourseId,
      divisionId,
      intervalId,
      from,
      limit,
    },
    {
      pollingInterval: isLive && !usingSse
        ? 30_000
        : undefined,
      skip: !eventCourseId || !divisionId || !intervalId,
    }
  )
  const isLoading = isFetching && !data

  const mapped = data
    && data.results?.length
    ? [
      {
        eventId: athlinksEventId,
        eventCourseId,
        interval: {
          intervalFull: data.interval.full,
          intervalName: data.interval.name,
          intervalResults: transformReigniteIntervalResults({
            division: data.division,
            interval: data.interval,
            results: data.results,
            source: data.source,
          })
        },
        thirdParySystemRaceId: data.race.id,
        totalAthletes: data.division.totalAthletes,
      }
    ]
    : []

  return (
    <>
      {usingSse && <ReigniteEventSseResults
        athlinksEventId={athlinksEventId}
        divisionId={divisionId}
        eventCourseId={eventCourseId}
        intervalId={intervalId}
        refetch={refetch}
      />}
      <EventResultsComponent
        {...props}
        fetchEventResultsError={isError}
        eventResults={{
          data: mapped,
          isLoading,
        }}
        isFetchingEventResults={isFetching}
        refetch={refetch}
        useRtkq={true}
      />
    </>
  )
}
