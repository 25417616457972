import { makeStyles } from '@material-ui/core';

import { fonts } from '../../shared/styles';

const content = {
  display: 'block',
  fontSize: 14,
  fontWeight: 400,
  letterSpacing: '0.17px',
  lineHeight: '20px',
}

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.grey[800],
    fontSize: 14,
    letterSpacing: 0.4,
    lineHeight: '24px',
    marginBottom: '8px',
    textTransform: 'uppercase',
    ...fonts.bold,
  },
  link: {
    ...content,
    color: theme.palette.brandColor,
  },
  text: {
    ...content,
    color: theme.palette.grey[800],
  },
}));

export { useStyles };