import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { GridList, IconButton, Modal } from '@material-ui/core';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'

import Dimensions from '../reactDimensions/ReactDimensions';
import { MediaGridLightbox } from './MediaGridLightbox';
import { MediaGridTile } from './MediaGridTile';
import styles from './styles';

const certificateDefaultWidth = 1200;
const certificateDefaultHeight = 630;

/**
 * A video and photo gallery component with built-in lightbox display.
 *
 * @TODO Allow tile and lightbox customizations.
 */
class MediaGridComponent extends Component {
  static defaultStyles = styles;
  static propTypes = {
      /**
       * An array of objects with the following contracts:
       *
       * Photos:
       *  poster: string - URL to a low-res version of the photo,
       *  url: string - URL to full-res photo,
       *  title: string - Text to display below photo,
       *  type: 'photo',
       *  toggled: Boolean - whether to display the action icon as on or off.
       *
       * Videos:
       *  poster: string - URL to a screen shot,
       *  url: string - URL to video file (video/mp4 only),
       *  offsetTime: number - Number of seconds into the video to begin playback
       *  title: string - Text to display below photo,
       *  type: 'photo',
       *  toggled: Boolean - whether to display the action icon as on or off.
       *
       * The first row of the results will be displayed double sized ('featured'), unless on mobile in
       * a portrait orientation.
       */
      photos: PropTypes.array,
      /**
       * Height of each row in pixels.
       * @type {number}
       * @default 200
       */
      rowHeight: PropTypes.number,
      /**
       * Number of rows display initially.
       * Default behavior is to show enough rows to contain the entire data set.
       * If number specified is less than the number of entries in the data, the
       * final tile will be a more button that will load an additional set of rows,
       * or the remainder of the tiles if what's remaing is less than that.
       *
       * The value here represents the amount of real estate to use, not the actual number
       * of rows displayed. If the data set contains featured entries, they'll occupy double rows
       * and double columns, so keep that under consideration when ordering your data.
       *
       * @type {number}
       * @default NaN
       */
      rows: PropTypes.number,
      /**
       * Space between cells in pixels.
       * @type {number}
       * @default 10
       */
      padding: PropTypes.number,
      /**
       * Callback for handling accessory icon clicks. This function
       * will receive the object assigned to the particular tile.
       *
       * <code>(tile) => console.log(tile.url)</code>
       *
       * Note: You can have additional properties in your photos collection's objects,
       * and they will be included with this parameter's value.
       * @type {function}
       * @default (tile) => console.log('clicked action icon for ', tile.url)
       */
      actionCallback: PropTypes.func,
      /**
       * SvgIcon - Icon to use as the default accessory action.
       * @type SvgIcon
       * @default {ActionFavoriteBorder}
       */
      actionIcon: PropTypes.object,
      /**
       * Color of the accessory icon.
       * @type {string|uint}
       * @default 'white'
       */
      actionIconColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      /**
       * SvgIcon - Icon to use as the default accessory action.
       * @type SvgIcon
       * @default {ActionFavorite}
       */
      toggledActionIcon: PropTypes.object,
      /**
       * Color of the accessory icon in toggled state.
       * @type {string}
       * @default 'white'
       */
      toggledActionIconColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      /**
       * Factory function to generate containers to use in the grid
       */
      tileFactory: PropTypes.func,
      /**
       * Indicates whether the device is in a tablet form factor
       * Hint: Wrap in a ResizeContainer to get this for free
       */
      isTablet: PropTypes.bool,
      /**
       * Indicates whether the device is in a mobile form factor
       * Hint: Wrap in a ResizeContainer to get this for free
       */
      isMobile: PropTypes.bool,
      /**
       * Indicates whether the device is in a landscape orientation
       * Hint: Wrap in a ResizeContainer to get this for free
       */
      isLandscape: PropTypes.bool,

      /**
       * Style used for title bar background.
       * Useful for setting custom gradients for example
       */
      titleBackground: PropTypes.string,
      /**
       * Position of the title bar (container of title, subtitle and action icon).
       */
      titlePosition: PropTypes.oneOf(['top', 'bottom']),
      /**
       * Additional close action styling
       */
      closeActionStyling: PropTypes.object,
      /**
       * Open Light Box callback function
       */
      openLightboxCallback: PropTypes.func,
      /**
       * Load more event callback function
       */
      loadMoreCallback: PropTypes.func,

      facebookShareCallback: PropTypes.func,
      facebookShareIcon: PropTypes.func,
      isCertificate: PropTypes.bool
    };

  static defaultProps = {
    photos: [],
    rowHeight: 200,
    rows: NaN,
    padding: 16,
    actionIcon: FavoriteBorderIcon,
    actionIconColor: 'white',
    toggledActionIcon: FavoriteIcon,
    toggledActionIconColor: 'red',
    actionCallback: (tile) => console.log('clicked action icon for ', tile.url),
    tileFactory: null,
    isTablet: false,
    isMobile: false,
    isLandscape: true,
    titlePosition: 'bottom',
    titleBackground: styles.grid.titleBackground,
    closeActionStyling: {},
    prerollMedia: {},
    prerollAudioUrl: '',
    facebookShareIcon: null,
    isCertificate: false
  };

  constructor(props) {
    super(props);

    this.modalOpen = false

    this.state = {
      visibleTiles: 0
    };

    this.actionIconClickInterceptor = this.actionIconClickInterceptor.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this)
    this.facebookShareClickInterceptor = this.facebookShareClickInterceptor.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.loadMore = this.loadMore.bind(this);
  }

  actionIconClickInterceptor(tile) {
    return (event) => {
      if (this.props.actionCallback) {
        this.props.actionCallback(tile);
      }
    }
  }

  closeLightbox() {
    this.modalOpen = false
    this.forceUpdate()
  }

  facebookShareClickInterceptor(tile) {
    return (event) => {
      if (this.props.facebookShareCallback) {
        this.props.facebookShareCallback(tile);
      }
    }
  }

  openLightbox(tile) {
    return (event) => {
      if(this.props.openLightboxCallback) this.props.openLightboxCallback();
      this.setState({tile}) //, (_) => this.refs.portal.openPortal());
      this.modalOpen = true
      this.forceUpdate()
    }
  }

  loadMore(current, count) {
    if(this.props.loadMoreCallback) this.props.loadMoreCallback();
    this.setState({visibleTiles: current + count});
  }

  getCellHeight(isMobile, isTablet, containerWidth, padding, isCertificate) {
    if(isCertificate) {
      // (original height / original width) * new width
      return (certificateDefaultHeight / certificateDefaultWidth) * containerWidth;
    }
    if(isMobile) {
      return containerWidth / (4/3);
    }
    else if(isTablet) {
      return (containerWidth / 2 - 3 * padding) / (4/3);
    }

    return (containerWidth / 4 - 5 * padding) / (4/3);
  }

  render() {
    /*
     Mobile:
      Portrait: 1 col, 4 rows
      Landscape: 2 col, 2 rows
     Tablet:
      Portrait: 2 col, 2 rows,
      Landscape: 4 col, 2 rows
     Desktop:
      All: 4 col, 2 rows
    */

    if (this.props.photos && this.props.photos.length) {
      const padding = this.props.padding;
      // // Commenting out until we have proper device detection.
      // const cols = this.props.isMobile ? (this.props.isLandscape ? 2 : 1) :
      //             this.props.isTablet && !this.props.isLandscape ? 2 : 4;
      // const rows = this.props.isMobile && !this.props.isLandscape ? 4 : 2;
      // const height = this.props.isMobile ?
      //                 (this.props.isLandscape ? this.props.containerWidth / 2 - 3 * padding : this.props.containerWidth - padding) :
      //               this.props.isTablet ?
      //                 (this.props.isLandscape ? this.props.containerWidth / 4 - 5 * padding : this.props.containerWidth / 2 - 3 * padding) :
      //               200;
      const cols = this.props.isMobile  ? 1 :
                    this.props.isTablet ? 2 : 4;
      const rows = this.props.isMobile ? 4 : 2;
      const height = this.props.heightOverride ||
        this.getCellHeight(this.props.isMobile, this.props.isTablet, this.props.containerWidth, padding, this.props.isCertificate);

      const visibleTiles = this.state.visibleTiles || (rows * cols - (Math.floor(cols / 2)))
      const remaining = this.props.photos.length - visibleTiles + (this.props.photos.length - visibleTiles <= cols ? 1 : 0);
      const visibleData = this.props.photos.filter((x, idx) => {
        return idx < visibleTiles - (remaining > 0 ? 1 : 0);
      });
      const {titlePosition, titleBackground} = this.props;

      const composedData = remaining > 0 ? visibleData.concat([
              Object.assign({}, visibleData[visibleData.length - 1], {
                type: 'more'
              })
            ]) : visibleData;

      const moreTile = (tile, divstyle, c, r) => {
        const moreStyle = Object.assign({}, divstyle, styles.grid.moreTileEffects);
        const moreLabel = cols === 1 ? Math.min(remaining, 5) : Math.min(remaining, cols);

        return (
          <div style={{padding}}>
            <div cols={c} rows={r} key='moreTile' style={{...styles.grid.moreTileContainer}}>
              <div style={moreStyle} onClick={(e) => this.loadMore(composedData.length, moreLabel)} />
              <div style={Object.assign({}, styles.grid.moreButton, {cursor: 'pointer'})}
                onClick={(e) => this.loadMore(composedData.length, moreLabel)}>
                <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translateY(-50%) translateX(-50%)'}}>
                  +{moreLabel}
                </div>
              </div>
            </div>
          </div>
        )
      };

      const gridTiles = composedData.map((tile, idx) => {
        const iconConfig = {
          icon: tile.toggled ? this.props.toggledActionIcon : this.props.actionIcon,
          color: tile.toggled ? this.props.toggledActionIconColor : this.props.actionIconColor,
          featured: cols > 1 && idx < cols / 2
        };

        const actionIcon =
          <IconButton onClick={this.actionIconClickInterceptor(tile)}>
            <iconConfig.icon htmlColor={iconConfig.color} />
          </IconButton>;

        const facebookShareIcon =
          <IconButton onClick={this.facebookShareClickInterceptor(tile)}>
            <this.props.facebookShareIcon color={iconConfig.color} />
          </IconButton>;

        const divstyle = Object.assign({}, styles.grid.imageTile(tile.isCertificate), {backgroundImage: `url('${tile.poster || tile.url}')`});
        const columns = tile.isCertificate ? 4 : iconConfig.featured ? 2 : 1

        return tile.type === 'more' ? moreTile(tile, divstyle, iconConfig.featured ? 2 : 1, iconConfig.featured ? 2 : 1) : (
            <MediaGridTile
              key={idx + tile.url}
              title={tile.title}
              actionIcon={actionIcon}
              actionPosition='right'
              facebookShareIcon={facebookShareIcon}
              titlePosition={titlePosition}
              titleBackground={titleBackground}
              cols={columns}
              rows={tile.isCertificate ? 1 : iconConfig.featured ? 2 : 1}
              spacing={padding}
              style={{position: 'relative'}}
            >
              <div style={divstyle} onClick={this.openLightbox(tile)} />
              {tile.type === 'video' && (
                <PlayCircleOutlineIcon color='white' style={styles.grid.playButton}
                  onClick={this.openLightbox(tile)} />
              )}
            </MediaGridTile>
        )}
      );

      return  (
        <div
          itemScope id="test"
          itemType="http://schema.org/ImageGallery"
          style={styles.root}
        >
          <GridList
            cols={cols}
            cellHeight={height}
            spacing={padding}
            style={styles.gridList}
            children={gridTiles}
          />
          <Modal open={this.modalOpen}>
            <MediaGridLightbox
              media={this.props.photos}
              prerollMedia={this.props.prerollMedia}
              prerollAudioUrl={this.props.prerollAudioUrl}
              tile={this.state.tile}
              closePortal={this.closeLightbox}
              isTablet={this.props.isTablet}
              isMobile={this.props.isMobile}
              isLandscape={this.props.isLandscape}
              windowWidth={this.props.windowWidth}
              windowHeight={this.props.windowHeight}
              actionIcon={this.props.actionIcon}
              actionCallback={this.props.actionCallback}
              facebookShareIcon={this.props.facebookShareIcon}
              facebookShareCallback={this.props.facebookShareCallback}
              closeActionStyling={this.props.closeActionStyling}
              />
          </Modal>
        </div>
      )
    }
    else {
      return null;
    }
  }
}

export const MediaGrid = Dimensions()(MediaGridComponent)