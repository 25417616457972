import { IRPBodyContainerComponent } from '../../components/IRP/body/IRPBodyContainer'
import { EventCourses } from '../../utils/types'
import { useGetIndividualResultQuery } from '../../api/reigniteApi'
import convertRaceTypeToTimeUnits from '../../utils/convertRaceTypeToTimeUnits'
import convertTimeUnitsToOldTimeUnit from '../../utils/convertTimeUnitsToOldTimeUnit'
import transformReigniteEntryIntervalPace from '../../utils/transformReigniteEntryIntervalPace'
import transformReigniteEntryIntervalTime from '../../utils/transformReigniteEntryIntervalTime'
import useSse from '../../lib/firebase/useSse'
import { ReigniteIndividualSseResult } from './ReigniteIndividualSseResult'
import useIrpMedia from '../../hooks/useIrpMedia'
import useFinisherCertificate from './useFinisherCertificate'

export function ReigniteIndividualResult(
  props: {
    athlinksEventId: number
    bib?: string
    courses: EventCourses
    entryId?: number
    event?: {
      ctliveId?: number
      timeZone: string
    }
    eventCourseId: number
    isLive: boolean
  }
) {
  const {
    athlinksEventId,
    bib,
    courses,
    eventCourseId,
    entryId,
    isLive,
  } = props

  const usingSse = useSse(athlinksEventId) && isLive

  const {
    currentData: data,
    isFetching,
    refetch,
  } = useGetIndividualResultQuery(
    {
      bib,
      entryId,
      eventId: athlinksEventId,
      eventCourseId: eventCourseId || 0,
    },
    {
      pollingInterval: isLive && !usingSse
        ? 30_000
        : undefined,
      skip: !eventCourseId || (!entryId && !bib),
    }
  )

  const thirdPartyEntryId = data?.source !== 'mssql'
    ? +(data?.id ?? 0)
    : 0
  const media = useIrpMedia({
    thirdPartyEntryId,
    thirdPartyEventId: props?.event?.ctliveId ?? 0,
  })
  const certificate = useFinisherCertificate({
    athlinksEventId,
    bib,
    entryId,
    eventCourseId,
    thirdPartyEntryId,
  })

  if (!data) {
    return (
      <>
        <IRPBodyContainerComponent
          {...props}
          courses={courses}
          result={{
            fetching: isFetching,
          }}
          fetching={isFetching}
        />
      </>
    )
  }

  const {
    id,
    intervals,
    location,
    private: isPrivate, // 'private' is a reserved word in strict mode.
    race,
    source,
    ...pii
  } = data

  const [firstName, lastName] = pii.displayName.split(' ')

  const mapped = {
    ...pii,
    ...location,
    eventCourseId,
    eventId: athlinksEventId,
    id: source === 'mssql' ? id : 0,
    firstName,
    lastName,
    intervals: Array.from(intervals)
      .sort((a, b) => b.distance.meters - a.distance.meters)
      .map((x, i) => ({
        ...x,
        mostRecentInterval: !i, // first course interval is latest
      }))
      .sort((a, b) => a.distance.meters - b.distance.meters)
      .map(({
        chipTimeInMillis,
        divisions,
        gunTimeInMillis,
        startTimeInMillis,
        ...interval
      }) => {

        const division = divisions[0]  // TODO

        const timeUnit = convertTimeUnitsToOldTimeUnit(
          convertRaceTypeToTimeUnits(interval.raceType)
        )
        return {
          ...interval,
          brackets: divisions.map(d => ({
            ...d,
            bracketName: d.name,
            bracketRankType: d.rankType,
            bracketType: d.type,
          })),
          chipTime: {
            timeInMillis: chipTimeInMillis,
            timeUnit,
          },
          gunTime: {
            timeInMillis: gunTimeInMillis,
            timeUnit,
          },
          intervalFull: interval.name?.toUpperCase() === 'FULL COURSE', // TODO
          intervalName: interval.name,
          pace: transformReigniteEntryIntervalPace({
            chipTimeInMillis,
            division,
            gunTimeInMillis,
            interval,
          }),
          timeWithPenalties: transformReigniteEntryIntervalTime({
            chipTimeInMillis,
            division,
            gunTimeInMillis,
            interval,
          })
        }
      }),
    isProfilePublic: !isPrivate,
    racerStartTime: {
      timeInMillis: data.intervals[0]?.startTimeInMillis,
      timeZone: props.event?.timeZone,
    },
    thirdPartyEntryId,
  }

  return (
    <>
      {usingSse && <ReigniteIndividualSseResult
        athlinksEventId={athlinksEventId}
        bib={bib}
        eventCourseId={eventCourseId}
        refetch={refetch}
      />}
      <IRPBodyContainerComponent
        {...props}
        certificate={certificate}
        courses={courses}
        result={mapped}
        fetching={isFetching}
        media={media}
      />
    </>
  )
}
