import {
  textStyles,
  colors,
} from '../../shared/styles';

export const styles = {
  loaderContainer: {
    padding: '100px 25px 40px',
    backgroundColor: colors.white,
  },
  contentCardMobile: {
    minHeight: 300,
    padding: '17px 15px 40px',
    backgroundColor: colors.white,
    boxShadow: colors.cardShadow,
  },
  contentCard: {
    minHeight: 300,
    padding: '17px 25px 40px',
    backgroundColor: colors.white,
    boxShadow: colors.cardShadow,
  },
  detailRow: {
  	...textStyles.body1Grey,
  },
  listContainer: {
      position: 'relative',
      minHeight: 290,
  },
  pagerContainer: {
   display: 'flex',
   justifyContent: 'center',
   paddingTop: 20
  },
}
