import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connectStream } from '../../lib/bastetjs/utils/connectStream';
import eventBus from '../../lib/event-bus-rx';
import { Nobles } from '../../lib/anuket';
import { imageStyles } from '../../shared/styles';
import { Loading } from '../../components/shared/Loading';
import {
  getResultUrl,
  getResultsUrlForBib,
  getResultsUrl,
} from '../../utils/resultsHelpers';
import { of as ObservableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { getExtraData } from '../../data/ExtraEventStream';
import _ from 'lodash';
import { connect } from 'react-redux';
import SearchStatus, { SearchState } from '../../components/shared/SearchStatus';
import { styles } from '../../components/IRP/body/styles';
import { trackGoogleEvent } from '../../utils/googleEvents';
import { getIndividualResultsStop } from '../../resultsClient/getIndividualResultsStop';
import { IRPResult } from '../eventResults/IRPResult'
import { getIndividualResults } from '../../utils/IRPUtil'

const mapStateToProps = (state) => ({
  errorMessage: state.individualResult.errorMessage,
  isLoading: state.individualResult.isLoading,
  result: state.individualResult.result,
  args: state.individualResult.args,
  irpArgs: state.individualResult.args,
  wasStopped: state.individualResult.wasStopped,
  dispatch: undefined
});

class IndividualResultComponent extends Component {
  static propTypes = {
    masterId: PropTypes.any,
    entryId: PropTypes.any, //needed by connectStream to update stream subscription when entryId changes
    event: PropTypes.object,
    race: PropTypes.array,
    result: PropTypes.object,
    isLive: PropTypes.bool,
    extra: PropTypes.object,
    image: PropTypes.object,
    source: PropTypes.string,
    t: PropTypes.func,
    useRtkq: PropTypes.bool
  };

  static defaultProps = {
    event: { isLoading: true },
    race: [{ isLoading: true }],
    isLive: false,
    extra: {},
    useRtkq: false
  };

  componentDidMount() {
    const {
      bib,
      ctLiveEntryId,
      entryId,
      eventCourseId,
      eventId,
      masterId,
      source,
      useRtkq,
    } = this.props;

    const haveEntryId = !!bib || !!ctLiveEntryId || !!entryId;

    if (masterId && eventId && eventCourseId && haveEntryId && !useRtkq) {
      getIndividualResults(this.props.dispatch, eventId, eventCourseId, bib, ctLiveEntryId, entryId)
    }
    if (masterId) {
      trackGoogleEvent('IRP', 'Visit', `${source}`)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.masterId && this.props.masterId !== nextProps.masterId) {
      //masterId will be object if there's an error...
      if (typeof nextProps.masterId !== 'object') {
        //Redirect to ELP once we know masterId...
        const {
          eventId,
          eventCourseId,
          entryId,
          masterId,
          bib,
          source
        } = nextProps;

        //If we don't have bib or entry id, but have master and eventid, redirect to results
        if (masterId && eventId && !(entryId || bib)) {
          this.props.history.replace(`/event/${masterId}/results/Event/${eventId}/Results`);
        }
        else {
          const url = new URL(window.location.href);
          const category = url.searchParams.get('category') || null;
          const term = url.searchParams.get('term') || null;
          this.props.history.replace(entryId ?
            getResultUrl(eventId, eventCourseId, entryId, { masterId }, category, term, [`source=${source}`]) :
            getResultsUrlForBib(eventId, eventCourseId, bib, masterId, null, category, term, [`source=${source}`])
          );
        }
      }
    }

    let {
      bib,
      ctLiveEntryId,
      entryId,
      eventCourseId,
      eventId,
      result,
      masterId,
      useRtkq,
    } = nextProps;

    if (masterId && (masterId !== this.props.masterId) && !_.isEmpty(this.props.result) && _.isEmpty(result) && !useRtkq) {
      getIndividualResults(this.props.dispatch, eventId, eventCourseId, bib, ctLiveEntryId, entryId)
    }
  }

  componentWillUnmount() {
    const {
      irpArgs,
      dispatch
    } = this.props;

    const {
      eventId,
      eventCourseId,
      bib,
      thirdPartyEntryId,
      athlinksEntryId
    } = irpArgs;

    if (eventId && eventCourseId) {
      dispatch(
        getIndividualResultsStop({
          eventId,
          eventCourseId,
          bib,
          thirdPartyEntryId,
          athlinksEntryId
        })
      );
    }
  }

  /**
   * If there is not referrer path or referrer path is equal to the current
   * path then hitting "Go Back" should redirect to ERP page. Closest ERP view
   * we can get for not loaded IRP page is based on event course id. IF IRP
   * does not load then we do not have information about split and division
   */
  backToResults = () => {
    const {
      eventId,
      masterId,
      referrerPath,
      eventCourseId,
      history
    } = this.props;
    const { location: { pathname = null } = {} } = window || {};
    if (!referrerPath || referrerPath === pathname) {
      history.push(getResultsUrl(masterId, eventId, eventCourseId));
    } else {
      history.goBack();
    }
  };

  renderLoader() {
    const {
      t
    } = this.props;

    return (
      <div style={{ ...imageStyles.loadingOverlay, height: 300 }}>
        <div style={imageStyles.loadingPosition}>
          <Loading timeoutMessage={t('Still thinking...')} />
        </div>
      </div>
    );
  }

  render() {
    const {
      bib,
      entryId,
      event = {},
      extra: { sponsorLogos = [], certificateSettings = {} },
      image,
      isLive,
      isLoading,
      isMobile,
      mapPreview,
      masterId,
      race,
      result,
      t,
      useRtkq,
    } = this.props;

    const eventInfo = _.get(event, 'event', event || {});
    const raceLoading = _.isEmpty(race) || race[0].isLoading;
    const eventLoading = _.isEmpty(event) || event.isLoading;
    const loading = isLoading || raceLoading || eventLoading || !masterId;
    if (loading) {
      return (
        <div className={isMobile ? 'col-12 container' : 'col-8 container'} style={styles.cardContainer}>
          {this.renderLoader()}
        </div>
      );
    }

    if (!useRtkq && _.isEmpty(result)) {
      return (
        <div className={isMobile ? 'col-12 container' : 'col-8 container'} style={styles.cardContainer}>
          <SearchStatus
            searchState={loading ? SearchState.SEARCHING : SearchState.RESULTS_FAILED}
            retrySearch={this.backToResults}
            buttonLabel='Go Back'
            t={t}
            searchCopy={{
              [SearchState.SEARCHING]: this.renderLoader(),
              [SearchState.RESULTS_FAILED]: t('Sorry. We have made a few attempts but have failed you.')
            }}
            altStyle={true}
          />
        </div>
      )
    }

    return (
      <IRPResult
        athleteId={result.racerId}
        bib={bib}
        certificateSettings={certificateSettings}
        entryId={entryId}
        event={eventInfo}
        image={image}
        irpResult={result}
        isLive={isLive}
        isMobile={isMobile}
        mapPreview={mapPreview}
        race={race[0]}
        sponsorLogos={sponsorLogos}
        useRtkq={useRtkq}
      />
    );
  }
}

function getEvent(eventId) {
  Nobles.EventsNoble.getEventByEventId({ eventId })
    .subscribe((msg) => eventBus.publish('irp-event', msg));
  return eventBus.getTopic('irp-event');
}

export const IndividualResult = connect(mapStateToProps)(withTranslation()(connectStream({
  masterId: ({ masterId, eventId }) => masterId ? ObservableOf(masterId) : getEvent(eventId).pipe(map((event) => (event.event || {}).masterId)),
  event: ({ eventId }) => getEvent(eventId),
  extra: ({ eventId }) => getExtraData(eventId)
})(withRouter(IndividualResultComponent))));
