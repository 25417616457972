import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { SocialShare } from '../shared/SocialShare';
import { getResultsUrl } from '../../utils/resultsHelpers';
import { isRosterAvailable } from '../../utils/rosterHelpers';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

interface EventHeaderTabMenuProps {
  masterId: number;
  erpEventId: number;
  event: any;
  isMobile: boolean;
}

const EventHeaderTabMenu = ({
  masterId,
  erpEventId,
  event,
  isMobile,
}: EventHeaderTabMenuProps) => {
  const { t } = useTranslation();
  const styles = useStyles({});

  const tabs = useMemo(() => {
    const allTabs = [
      {
        key: 'about',
        label: t('about'),
        url: `/event/${masterId}`,
      },
      {
        key: 'roster',
        label: t('roster'),
        url: `/event/${masterId}/roster`,
      },
      {
        key: 'results',
        label: t('results'),
        url: getResultsUrl(masterId, erpEventId),
      },
    ];

    if (event && !isRosterAvailable(event)) {
      return allTabs.filter((tab) => tab.key !== 'roster');
    }

    return allTabs;
  }, [t, masterId, erpEventId, event]);

  return (
    <div className={styles.tabMenuContainer}>
      <div>
        {tabs.map((tab, index) => (
          <NavLink
            exact
            key={index}
            to={tab.url}
            activeClassName={styles.tabMenuActiveTab}
            className={styles.tabMenuTab}
          >
            {tab.label}
          </NavLink>
        ))}
      </div>

      <SocialShare
        followButton={false}
        isMobile={isMobile}
        buttonsProps={{
          event,
          isMobile
        }}
      />
    </div>
  );
};

export { EventHeaderTabMenu };
