import { ResultActionTypes } from "./types";
import { InternalClientActionType } from "./types";

export const getEventResultsStopInternal = (
    action
  ) => (
      {
        ...action,
        type: InternalClientActionType.GET_EVENT_RESULTS_DATA_STOP_POLLING
      }
    )


export const getEventResultsStop = (
  args
) => ({
  type: ResultActionTypes.GET_EVENT_RESULTS_STOP,
  args
})
