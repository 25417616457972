import { FC } from 'react';
import { injectIntl } from 'react-intl'
import { withTranslation } from 'react-i18next'
import { Loading } from '../../components/shared/Loading'
import { compose } from 'redux'
import { imageStyles } from '../../shared/styles';
import { styles } from './styles'
import { Grid, InputLabel, Portal } from '@material-ui/core'
import { useGetRosterQuery } from '../../api/reigniteApi'
import { useEventCourseIdentifiers } from './useEventCourseIdentifiers'
import { RosterGrid } from './RosterGrid'
import { PageView } from './PageView'
import { SearchResult } from './SearchResult'
import { EventCourseSelect } from './EventCourseSelect'
import { EventSelect } from './EventSelect'
import { usePushHistory } from './usePushHistory'
import { useSearchParams } from './useSearchParams';

type Props = {
  races: any[]
  isMobile: boolean
  t: any
  intl: any
  roster?: {
    page: number
    pageSize: number
    results: any[]
    total: number
  }
  error?: any
  isLoading: boolean
  eventCourses: any[]
  eventCourse: any
  eventCourseId: number
  eventId: number
  masterId: number
}

export const EventRosterViewComponent = ({
  races,
  isMobile,
  t,
  intl,
  roster,
  error,
  isLoading,
  eventCourses,
  eventCourse,
  eventCourseId,
  eventId,
  masterId,
}: Props) => {
  const { term } = useSearchParams();
  const pushHistory = usePushHistory({ masterId, eventId, eventCourseId })

  if (!roster && !error) {
    return (
      <PageView
        isMobile={isMobile}
        pushHistory={pushHistory}
        t={t}
      >
        <Portal>
          <div style={styles.loaderContainer}>
            <div style={imageStyles.loadingPosition as any}>
              <Loading timeoutMessage={t('Still thinking...')} />
            </div>
          </div>
        </Portal>
      </PageView>
    )
  }

  if (error) {
    console.error(error)
    return (
      <PageView
        isMobile={isMobile}
        pushHistory={pushHistory}
        t={t}
      >
        <div>Error</div>
      </PageView>
    )
  }

  return (
    <PageView
      isMobile={isMobile}
      pushHistory={pushHistory}
      t={t}
    >
      <Grid container direction="row">
        <Grid item md={6} xs={12} style={{ padding: 16 }}>
          <InputLabel htmlFor="event-select">{t('Event')}</InputLabel>
          <EventSelect
            eventId={eventId}
            pushHistory={pushHistory}
            races={races}
          />
        </Grid>
        <Grid item md={6} xs={12} style={{ padding: 16 }}>
          <InputLabel htmlFor="course-select">{t('Course')}</InputLabel>
          <EventCourseSelect
            eventCourseId={eventCourseId}
            eventCourses={eventCourses}
            pushHistory={pushHistory}
          />
        </Grid>
        <Grid item xs={12}>
          <SearchResult
            eventCourse={eventCourse}
            intl={intl}
            isLoading={isLoading}
            roster={roster}
            term={term}
          />
        </Grid>
        <RosterGrid
          isLoading={isLoading}
          isMobile={isMobile}
          roster={roster}
          t={t}
        />
      </Grid>
    </PageView>
  )
}

type PureProps = {
  intl: any
  isMobile: boolean
  races: any[]
  t: any
}

const PureEventRosterViewComponent = (props: PureProps) => {
  const {
    races
  } = props
  const {
    eventCourseId,
    eventId,
    masterId,
  } = useEventCourseIdentifiers(props)
  const { page, term, pageSize } = useSearchParams();

  const { data, error, isLoading } = useGetRosterQuery({
    eventCourseId,
    page,
    pageSize,
    term,
  })

  const eventCourses = races?.find((x) => x.raceID === eventId)?.eventCourses || []
  const eventCourse = eventCourses.find((x: any) => x.eventCourseID === eventCourseId)
  return (
    <EventRosterViewComponent
      {...props}
      error={error}
      eventCourse={eventCourse}
      eventCourseId={eventCourseId}
      eventCourses={eventCourses}
      eventId={eventId}
      isLoading={isLoading}
      masterId={masterId}
      roster={data}
    />
  )
}

export const EventRosterView = compose(
  withTranslation(),
  injectIntl,
)(PureEventRosterViewComponent) as FC<Omit<Props, 't' | 'intl'>>
