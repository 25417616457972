import React, { Component } from 'react';
import { Configure } from './lib/anuket-http';
import { IntlProvider } from 'react-intl';
import { connect, Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { setRefreshToken, setAccessToken, setIdToken } from './data/AuthStreams';
import { fetchAthlete } from './data/AthleteStreams'
import { configs } from './configs';
import { Routes } from './routes.jsx';
import loadReCaptcha from './lib/react-recaptcha-google/loadReCaptcha';
import { getUserPrefsAction } from './actions/userPrefsAction';
import { availableLanguages } from './data/LanguageStreams';
import { configureInternalStore, configureStore } from './store';
import { theme } from './shared/theme';

window.APP_VERSION = process.env.REACT_APP_VERSION;
export const internalStore = configureInternalStore();
export const externalStore = configureStore();

externalStore.dispatch(
  getUserPrefsAction()
);

class App extends Component {
  state = {
    hasSentGAEvent: false,
    isLoggedIn: false,
  };
  setLocaleData = () => {
    if (!Intl.PluralRules) {
      import('@formatjs/intl-pluralrules/polyfill').then(() => {
        import(`@formatjs/intl-pluralrules/dist/locale-data/en`).catch(console.error)
      }).catch(console.error)

    }
    if (!Intl.RelativeTimeFormat) {
      import('@formatjs/intl-relativetimeformat/polyfill').then(() => {
        availableLanguages.map((lang) => import(`@formatjs/intl-relativetimeformat/dist/locale-data/${lang}`));
      }).catch(console.error)
    }
  };
  constructor(props, ctx) {
    super(props, ctx);
    this.setLocaleData();
    // For phantomjs
    if (!window.navigator) {
      window.navigator = {};
    }
    Configure.setConfigs(configs);
    window.kc_inflight = true;
    window.addEventListener('keycloakStateUpdate', (ev) => {
      // console.log('keycloakStateUpdate');
      this.updateKeycloakState(ev);
    });
    this.updateKeycloakState();
  }
  componentDidMount() {
    loadReCaptcha();
  }
  hotjar = () => {
    const hotjarKey = window.kc && window.kc.access_token ?
      configs.hotjarAuthenticated :
      configs.hotjarUnauthenticated;
    if (hotjarKey) {
      (function (h, o, t, j, a, r) {
        h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
        h._hjSettings = {
          hjid: hotjarKey,
          hjsv: 5
        };
        let hotjar = o.getElementById('hotjar');
        // we've already added hotjar
        if (hotjar) {
          if (hotjar.src.indexOf(hotjarKey) < 0) {
            // hotjarred, but with the wrong id
            // NOTE: this does not reinitialize Hotjar, but should be covered by the delay
            hotjar.parentNode.removeChild(hotjar);
            // console.log('Hotjar site id mismatch, Hotjar may not work');
          } else {
            // do nothing, we're already hotjarred and the keys match
            return;
          }
        }
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.id = 'hotjar';
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=');
    }
  };
  updateKeycloakState = () => {
    const keycloak = window.kc;
    // console.log('Keycloak updating state');
    if (keycloak.authenticated) {
      // console.log('Keycloak authenticated');
      if (keycloak.tokenParsed) {
        // console.log('have tokenParsed', keycloak.tokenParsed);
        Configure.setConfigs({
          access_token: keycloak.token,
          tokenParsed: keycloak.tokenParsed
        });
        setAccessToken({ token: keycloak.token });
        const expiresIn = (keycloak.tokenParsed['exp'] - Math.ceil(new Date().getTime() / 1000) + keycloak.timeSkew) * 1000;
        setTimeout(() => {
          keycloak.updateToken(keycloak.timeSkew + 5);
        }, Math.max(0, expiresIn - 4000));
        // console.log(`[Keycloak] will refresh token in ${Math.max(0, expiresIn)} ms`);
      }
      if (keycloak.refreshTokenParsed) {
        // console.log('have refreshTokenParsed', keycloak.refreshTokenParsed);
        setRefreshToken({ token: keycloak.refreshToken });
      }
      if (keycloak.idTokenParsed) {
        // console.log('have idTokenParsed', keycloak.idTokenParsed);
        setIdToken({ token: keycloak.idToken });
        fetchAthlete(keycloak.idTokenParsed['athlinks-racer-id'])
      }
      this.checkAccountCompletenessAndRedirect();
    }
    else {
      // console.log('Keycloak not authenticated');
      setAccessToken({ notAuthenticated: true });
      setRefreshToken({ notAuthenticated: true });
      setIdToken({ notAuthenticated: true });
    }
    if (!window.kc_inflight) {
      this.hotjar();
    }
    window.kc_inflight = false;
  };
  checkAccountCompletenessAndRedirect = () => {
    const keycloak = window.kc;
    if (keycloak.authenticated) {
      const token = keycloak.tokenParsed;
      this.setState({ isLoggedIn: true });
      if (!token['athlinks-racer-id']) {
        keycloak.accountManagement();
      } else {
        const { hasSentGAEvent } = this.state;
        if (window.ga && !hasSentGAEvent) {
          window.ga('gtm1.set', 'userId', keycloak.tokenParsed['athlinks-racer-id']);
          this.setState({ hasSentGAEvent: true });
        }
      }
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    // eslint-disable-next-line
    return nextState.isLoggedIn != this.state.isLoggedIn
  }

  render() {
    const { isLoggedIn } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        <React.Fragment>
          <Provider store={externalStore}>
            <InnerApp isLoggedIn={isLoggedIn} />
          </Provider>
        </React.Fragment>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.userPrefs.language
});

const InnerProviderRoutes = ({ isLoggedIn, language }) => {
  return (
    <IntlProvider locale={language}>
      <Routes isLoggedIn={isLoggedIn} />
    </IntlProvider>
  )
};

const InnerApp = connect(mapStateToProps)(InnerProviderRoutes);

export default App;
