import { isArray } from 'lodash'

export const getNonParamKeyVal = (obj) =>
  Object.keys(obj)
    .filter(k => k !== 'param')
    .map(key => ({ key, val: obj[key] })).pop()

export const getUrlParam = (key, val, param) =>
  (val !== undefined) ?
    `${param ? param : key}=${isArray(val) ? val.join(',') : val}` : ''

export const formatParams = (
  args
) => args.reduce(
  (acc, curr) => {
    const param = curr.param,
      { key, val } = getNonParamKeyVal(curr),
      accStr = (acc) ? acc : '',
      prefix = (val !== undefined && acc) ? '&' : (val !== undefined) ? '?' : ''
    return `${accStr}${prefix}${getUrlParam(key, val, param)}`
  }
  , undefined
)
