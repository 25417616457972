import { from, of } from 'rxjs'
import { ofType } from 'redux-observable'
import { flatMap, catchError } from 'rxjs/operators'
import { pollForData, stopPolling } from './util'
import { formatParams } from './params'
import { InternalClientActionType } from '../types'
import {
  getEventResultsErrorInternal,
  getEventResultsStartedInternal,
  getEventResultsStoppedInternal,
  getEventResultsUpdateInternal
} from '../getEventResults'

export function isQuery(args) {
  return args.searchTerm !== undefined
}

export function buildEventResultsQueryString(
  action
) {
  if (isQuery(action.args)) {
    const {
      eventId,
      searchTerm,
      fro,
      limit
    } = action.args
    return `event/${eventId}/search` +
      formatParams(
        [
          { searchTerm },
          { fro, param: `from` },
          { limit }]
      )
  } else {
    const {
      eventId,
      eventCourseId,
      divisionId,
      intervalId,
      fro,
      limit
    } = action.args
    return `event/${eventId}` +
      formatParams(
        [
          { eventCourseId },
          { divisionId },
          { intervalId },
          { fro, param: `from` },
          { limit }]
      )
  }
}

export function startEventResultsPolling(
  actions$
) {
  return actions$.pipe(
    ofType(InternalClientActionType.GET_EVENT_RESULTS_DATA),
    flatMap(
      action$ => {
        const { pollingInterval, args } = action$
        return from(
          pollForData(
            args,
            buildEventResultsQueryString(action$),
            pollingInterval,
            getEventResultsUpdateInternal,
            getEventResultsStartedInternal(args),
            getEventResultsErrorInternal
          )
        ).pipe(
          catchError(err => of(err))
        )
      }
    ),
    catchError(err => of(err))
  )
}

export function stopEventResultsPolling(
  actions$
) {
  return actions$.pipe(
    ofType(InternalClientActionType.GET_EVENT_RESULTS_DATA_STOP_POLLING),
    flatMap(action$ =>
      from(stopPolling(
        action$.args,
        buildEventResultsQueryString(action$),
        getEventResultsStoppedInternal(action$.args),
        getEventResultsErrorInternal
      )).pipe(
        catchError(err => of(err))
      )
    ),
    catchError(err => of(err))
  )
}
