import { DistanceUnits } from "../types.results"

export enum CtLiveApiStatus {
  New = 'NEW',
  Confirmed = 'CONF',
  Disqualified = 'DQ',
  Withdrawn = 'WITHDRAWN',
  DidNotFinish = 'DNF',
  Pending = 'PENDING',
  NotSelected = 'NOT_SELECTED',
  Unranked = 'UNRANKED',
  Deferred = 'DEFERRED'
}

export type IrpResult = {
  age: number
  athlinksEventCourseId: number
  athlinksEventId: number
  bib: string
  ctLiveEntryId: number
  entryStatus: CtLiveApiStatus
  eventId: number
  gender: string
  id: string
  raceId: number
  results: {
    brackets: {
      bracketName: string
      chipTimeInMillis: number
      gunTimeInMillis: number
      id: number
      isPrimary: boolean
      pace: string
      paceUnit: string
      penalty: number
      rank: number
      totalAthletes: number
      totalInBracket: number
    }[]
    intervalFull: boolean
    [key: string]: any
  }[]
  thirdPartyEntryId: number
  [key: string]: any
}

export type IrpMedia = {
  entryId: number
  photos: {
    highUrl: string
    lowUrl: string
  }[]
  preroll: {
    isPublished: boolean
    provider: string
    url: string
  }
  thirdPartyEntryId: string
  videos: {
    thumbnail: string
    url: string
  }[]
}

export type AffiliateLink = {
  RaceID: number
  AffiliateID: number
  AffiliateRaceID: string
  LinkTypeID: number
}

export type EventCourse = {
  AffiliateLinks: AffiliateLink[]
  CourseRaceSummary: null
  CourseID: number
  CourseName: string
  CoursePattern: string
  CoursePatternID: number
  EventCourseID: number
  GenderCounts: any[]
  AgeClassCounts: any[]
  DistLabel: string
  DistTypeID: number
  DistUnit: number
  CountO: number
  RaceID: number
  RaceCatID: number
  RaceCatDesc: string
  LegCount: number
  ResultCount: number
  ResultsDate: string
  IsVirtual: boolean
  MinAllowedTicks: number
  MaxAllowedTicks: number
}

export type EventRace = {
  AffiliateLinks: AffiliateLink[]
  City: string
  CountryName: string | null
  RaceDate: string
  RaceDateOffset: string
  RaceDateString: string
  RaceID: number
  ResultCount: number
  RaceName: string
  StateProvName: string
  EventCourses: EventCourse[]
  WebSite: string
  MasterEventID: number
  IsCurrentRace: boolean
}

export type MasterEvent = {
  MasterEventID: number
  Name: string
  Affiliate: {
    AffiliateID: number
    Type: number
    Name: string
    Desc: null | string
    Url: null | string
    IsVisible: boolean
    IsJoinable: boolean
    CanPost: boolean
    Address: null | string
    City: null | string
    StateProvID: null | string
    Phone: null | string
    Fax: null | string
    JoinDate: string
    Settings: number
    Zip: null | string
    SecondaryTypes: any[]
  }
  City: string
  CountryName: string
  CountryID: string
  StateProvID: string
  StateProvAbbrev: string
  StateProvName: null | string
  Status: number
  NextRaceID: number
  PrevRaceID: number
  NextRaceDate: string
  PrevRaceDate: string
  NextRaceDateString: null | string
  PrevRaceDateString: null | string
  Elevation: number
  Weather: string
  NextRaceDescription: null | string
  PrevRaceDescription: null | string
  VanityURL: string
  WebSiteURL: null | string
  RegisterURL: null | string
  EventRaces: EventRace[]
  IsApproved: boolean
}

export type EventYearsDropDownItem = {
  Disabled: boolean
  Group: null | string
  Selected: boolean
  Text: string
  Value: string
}

type EventYearsDropDown = EventYearsDropDownItem[]

type RacerCountRangesFilterDropDownItem = {
  Disabled: boolean
  Group: null | string
  Selected: boolean
  Text: string
  Value: string
}

type ViewModeFilterDropDownItem = {
  Disabled: boolean
  Group: null | string
  Selected: boolean
  Text: string
  Value: string
}

type EventCoursesDropDownItem = {
  Disabled: boolean
  Group: null | string
  Selected: boolean
  Text: string
  Value: string
}

export type EventCourses = {
  MasterEvent: MasterEvent
  EventYearsDropDown: EventYearsDropDown
  SelectedRaceID: string
  SelectedRaceDate: string
  HasRsvp: boolean
  CTLiveRegistrationLink: string
  ShowShareResultsButton: boolean
  ShowAddNextRaceButton: boolean
  AverageEventOverall: number
  ViewType: number
  ShowStartEditMasterEventLink: boolean
  ShowCreateMasterEventButton: boolean
  OverallRating: number
  EventCourse: null // Adjust this type as needed
  ViewModeFilterDropDown: null | ViewModeFilterDropDownItem[]
  SelectedViewMode: string
  RacerCountRangesFilterDropDown: null | RacerCountRangesFilterDropDownItem[]
  SelectedViewModeFilterRange: string
  ShowRacerCountRangesFilter: boolean
  EventCoursesDropDown: EventCoursesDropDownItem[]
  SelectedCourseData: string
  ProfileRacerID: number
}

export type EventResultsProps = {
  eventMetadata: {
    data?: EventMetadata
    error: boolean
    fetching: boolean
  }
  eventResultsFilters: {
    course: number
    division: number
    split: number
    date: number
  }
  eventResults: {
    args: {
      fro: number
      limit: number
    }
  }
  isEventLive: boolean
  master?: {
    event?: {
      ctliveId: number
    }
  }
  page: number
  races: {
    races: {
      courseId: number
      eventCourseId: number
    }[]
  }
  schedule?: {
    athlinksCourseId: number
    eventCourseId: number
    raceId: number
  }[]
}

export type ReigniteEventResultsProps = EventResultsProps & {
  master: {
    event: {
      ctliveId: number
    }
  }
}

export type ReigniteIRPResultProps = {
  event: {
    athlinksId: number
    ctliveId: number
  },
  result: {
    raceId: number
  },
  race: {
    eventCourseId: number
  }
  bib?: string
  entryId?: number
}

export type ReigniteSsePayload = {
  type: 'results' | string
  payload: {
    bib?: string
    divisionId?: number
    eventId: number
    intervalId?: number
    raceId: number
    type: 'leaderboard' | 'rbi' | 'irp'
  }
}

export type SseState = {
  sse: {
    eventResults: {
      [path: string]: {
        lastPayload: ReigniteSsePayload
        lastUpdated: number
      }
    },
    irpResult: {
      [path: string]: {
        lastPayload: ReigniteSsePayload
        lastUpdated: number
      }
    }
  }
}

export type IntervalResults = {
  age: number
  bib: string
  country: string
  displayName: string
  entryId: number
  entryStatus: string
  entryType: string
  firstName: string
  gender: string
  genderRank: number
  intervalName: string
  intervalOrder: number
  intervalRaceType: string
  lastName: string
  locality: string
  overallRank: number
  pace: {
    distance: {
      distanceInMeters: number
      distanceUnit: string
    };
    time: {
      timeInMillis: number
      timeUnit: string
    };
    paceType: string
  };
  postalCode: string
  primaryBracketRank: number
  racerId: number
  region: string
  regionId: string
  thirdPartyEntryId: number
  time: {
    timeInMillis: number
    timeUnit: string
  }
}

export type Interval = {
  distance: {
    distanceInMeters: number
    distanceUnit: string
  };
  intervalFull: boolean
  intervalId: number
  intervalName: string
  intervalOrder: number
  intervalResults: IntervalResults[]
  intervalType: string
}

export type EventResult = {
  bracketId: number
  bracketName: string
  bracketRankType: string
  bracketType: string
  eventCourseId: number
  eventCourseName: string
  eventId: number
  hasPace: boolean
  interval: Interval
  thirdParySystemRaceId: number
  totalAthletes: number
}

export declare enum EventStatus {
  NotSet = -1,
  Declined = 0,
  Approved = 1,
  Hidden = 2,
  Rejected = 3,
  Pending = 4,
  Merged = 5
}

export type CalendarTime = {
  timeInMillis: number
  timeZone: string
}

export type IntervalType = "split" | "course" | "undefined"

export type DivisionMetadata = {
  id: number
  name: string
}
export type IntervalMetadata = {
  distance: number
  distanceUnit: DistanceUnits
  displayOrder: number
  id: number
  name: string
  isFull: boolean
  intervalType: IntervalType
}

export type IntervalDivisionMetadata = {
  divisions: DivisionMetadata[]
  intervals: IntervalMetadata[]
}

export type EventCourseMetadata = {
  courseId: number
  eventCourseId: number
  eventCourseName: string
  metadata: IntervalDivisionMetadata
  raceType: string
  distance: number
  eventCourseStartDateTime: CalendarTime
  isVirtual: boolean
  minAllowedTicks: number
  maxAllowedTicks: number
  eventCourseEndDateTime: CalendarTime
}
export type EventMetadata = {
  eventId: number
  eventCourseMetadata: EventCourseMetadata[]
  eventName: string
  eventStartDateTime: CalendarTime
  eventEndDateTime: CalendarTime
  eventStatus: EventStatus
}

export enum DistanceTypeId {
  mi = 1,
  ft = 3,
  yd = 4,
  m = 6,
  km = 7
}
